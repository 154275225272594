import React from 'react';

export const encrypt = (str) => {
    var resArray = [], dividedArr = [];

    resArray = string2Bin(str);
    resArray.map(x=>{
        var res = Math.floor(x/2*13/12+55)
        var res2 = Math.floor(Math.sqrt(res*res*res));
        var res3 = Math.floor(res2/13);
        //console.log(res);
        dividedArr.push(res);
    })
    return bin2String(dividedArr);
}
function string2Bin(str) {
    var result = [];
    for (var i = 0; i < str.length; i++) {
      result.push(str.charCodeAt(i));
    }
    return result;
}
function bin2String(array) {
    return String.fromCharCode.apply(String, array);
}