import { Content } from 'components/Layout';
import React from 'react';


class EmptyLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
        <Content fluid>{children}</Content>
      </main>
    );
  }
}

// const EmptyLayout = ({ children, ...restProps }) => (
//   <main className="cr-app bg-light" {...restProps}>
//     <Content fluid>{children}</Content>
//   </main>
// );

export default EmptyLayout;
