import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import { HeaderPortal } from 'components/Layout';
import {modalLoading} from '../../assets/custom/element'
import { UserCard } from 'components/Card';
import SearchInput from 'components/SearchInput';
import Avatar from 'components/Avatar';
import React from 'react';
import Typography from 'components/Typography';
import bg5Image from 'assets/img/bg/background_1920-5.jpg';
import logo_kominfo_white from 'assets/img/logo/logo_kominfo_white.png';
import asesmen_ic from 'assets/icon/asesmen.png';
import profil_ic from 'assets/icon/profil.png';
import sertifikat_ic from 'assets/icon/sertifikat.png';
import pengaturan_ic from 'assets/icon/pengaturan.png';
import Pagination from "react-js-pagination";
import { Card, Col, Row, CardBody,
    CardTitle, CardText, Button,Badge,
    Input,Label,FormGroup, Modal, ModalHeader, ModalBody, ModalFooter,
    CardImg, CardImgOverlay, CardHeader, Alert,
     Nav,Form,InputGroup, InputGroupAddon,
  Navbar, Table,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup, UncontrolledAlert,
  ListGroupItem,
} from 'reactstrap';
import {
    MdSearch,
    MdInsertChart,
    MdExitToApp,
    MdVisibilityOff,
    MdVisibility,
    MdShare,
    MdShowChart,MdWarning, MdCheckCircle,
    MdFullscreen,
    MdApps,MdClear,
    MdAdd,MdRefresh,
    MdLibraryAdd,MdContentCopy,MdAccountCircle,MdKeyboardBackspace,
  } from 'react-icons/md';
import { getTodayDate, makeid } from '../../utils/func/global_func';
import bn from 'utils/bemnames';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player'
import { encrypt } from '../../utils/func/auth_func';
import SertifikatPage from './SertifikatPage';
import PengaturanPage from './PengaturanPage';
import UpdateProfilPage from './UpdateProfilPage';

const images = [
    {src:'https://api.literasidigital.id/v1/photos/14/Menjadi_Pengguna_Internet_Anti-Hoax.webp', link:''},
    {src:'https://api.literasidigital.id/v1/photos/2/Bijak_Bermedia_Sosial_dalam_Fatwa_MUI.webp', link:''},
    {src:'https://api.literasidigital.id/v1/photos/1/Tumbuh_Kembang_Anak_Dengan_Media_Digital.webp', link:''},
    {src:'https://api.literasidigital.id/v1/photos/12/Indonesia_Pasar_Smartphone_Terbesar_Ke-3_Di_Asia-Pasifik.webp', link:''},
    {src:'https://api.literasidigital.id/v1/photos/11/Konten_Favorit_Warganet_Indonesia.webp', link:''},
    {src:'https://api.literasidigital.id/v1/photos/10/Media_Sosial_Favorit_Warganet_Indonesia.webp', link:''},
  ];
const bem = bn.create('header');
const menu_utama = [
    {src:asesmen_ic, text:"Ikuti Asesmen", name:'asesmen', id:1},
    {src:profil_ic, text:"Update Biodata", name:'profil', id:2},
    {src:sertifikat_ic, text:"Lihat Sertifikat", name:'sertifikat', id:3},
    {src:pengaturan_ic, text:"Pengaturan Akun", name:'pengaturan', id:4},
]
const books = [
    {src:"https://api.literasidigital.id/v1/photos/104/image_104.webp", href:"https://literasidigital.id/buku/modul-aman-bermedia-digital"},
    {src:"https://api.literasidigital.id/v1/photos/105/image_105.webp", href:"https://literasidigital.id/buku/modul-budaya-bermedia-digital"},
    {src:"https://api.literasidigital.id/v1/photos/191/image_191.webp", href:"https://literasidigital.id/buku/modul-cakap-bermedia-digital"},
    {src:"https://api.literasidigital.id/v1/photos/103/image_103.webp", href:"https://literasidigital.id/buku/modul-etis-bermedia-digital"},
]
class PortalPage extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            evid:null,
            preases:false,
            lquest_preases:[],
            submitted: false,
            modal:false,
            cek_email_data: {},
            cek_email_data2:{},
            state_questions: [],
            active_question:0,
            is_login:false,
            dataUser:{},
            innerPage:0,
            loading: false,
            narsumCert: [
                {nama_lengkap:"Jane Doe", no_sertif: "0012/V/TOT-Kominfo/2022"},
                {nama_lengkap:"John Doe", no_sertif: "0013/V/TOT-Kominfo/2022"},
                {nama_lengkap:"Dummy Jony", no_sertif: "0014/V/TOT-Kominfo/2022"},
                {nama_lengkap:"Ole Gunnar", no_sertif: "0015/V/TOT-Kominfo/2022"},
                {nama_lengkap:"Dembele", no_sertif: "0016/V/TOT-Kominfo/2022"},
                {nama_lengkap:"Dembele", no_sertif: "0016/V/TOT-Kominfo/2022"},
            ],
            activePage:1,
            itemPerPage:20,
            modalNarsum: false,
            narsumCertRender:[],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
        this.submitSignout = this.submitSignout.bind(this);
    }
    componentDidMount() {
        // this is needed, because InfiniteCalendar forces window scroll
        window.scrollTo(0, 0);
        if(localStorage.getItem("data")){
            this.setState({is_login:true, dataUser: JSON.parse(localStorage.getItem("data"))});
        }
        this.getList(0);
    }
    state = {
        isOpenNotificationPopover: false,
        isNotificationConfirmed: false,
        isOpenUserCardPopover: false,
      };
    toggleUserCardPopover = () => {
        this.setState({
          isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
        });
    };
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const key = target.name;
    
        if(target.type=="file"){
            //this.setState({[key]:event.target.files[0]});
            this.uploadFile(event.target.files[0]);
          }

        this.setState({
          [key]: value
        });
      }
    uploadFile(file){
        this.setState({loading: true});
        const data = new FormData();
        data.append('cv',file);

        const requestOptions = {
            ContentType: 'multipart/form-data',
            mode: 'cors',
            method: 'POST',
            body: data
        };
        fetch(process.env.REACT_APP_URL_API+'/app_trainer_upload_materi.php', requestOptions)
            .then(response => response.json())
            .then(respon => {
                var dataAPI = respon;
                //this.setState({loading: false});
                if(dataAPI.error){
                alert(respon.error);
                return;
                }else{
                    //this.sendNotif("File berhasil diupload","success");
                    var url = URL.createObjectURL(file);
                    this.setState({urlIFrame: url, materi:respon.fileName});
            }})
            .catch(e => {
                alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }
    handleKeyDown(event){
        if(event.keyCode === 13){
            event.preventDefault();
            //console.log(this.state.search)
            this.submitLogin(event);
        }
    }
    submitLogin(event){
        this.setState({loading:true});
        event.preventDefault();
        //this.setState({modal:true})
        const {password,email} = this.state; 
        if(password==undefined && email==undefined){
            alert("Harap isi password dan email");
            return;
        }
        const enc_pass = encrypt(password);
        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({ email: email, password: enc_pass })
        };
        fetch(process.env.REACT_APP_URL_API+'/app_login.php', requestOptions)
            .then(response => response.json())
            .then(respon => {
              var dataAPI = respon;
              this.setState({loading:false});
              if(dataAPI.error){
                this.setState({message:dataAPI.error});
              }else{
                localStorage.setItem("token", dataAPI.token);
                localStorage.setItem("data", JSON.stringify(dataAPI.data));
                localStorage.setItem("p_narsum", JSON.stringify(dataAPI.p_narsum));
                localStorage.setItem("p_lainnya", JSON.stringify(dataAPI.p_lainnya));
                localStorage.setItem("post_ases", JSON.stringify(dataAPI.post_ases));
                this.setState({is_login:true, nama: respon.data.nama, dataUser: dataAPI.data});
              }
            });
    }
    submitSignout(){
        localStorage.clear();
        window.location.href = "/";
    }
    cekPengerjaanAsemen(){
        //const {dataUser} = this.state;
        var dataUser = JSON.parse(localStorage.getItem("data"));
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ id_trainer: dataUser.id, id_event: dataUser.id_event })
          };
          fetch(process.env.REACT_APP_URL_API+'/app_portal_trainer_cek_asesmen.php', requestOptions)
              .then(response => response.json())
              .then(respon => {
                var dataAPI = respon;
                if(dataAPI.error){
                  alert(dataAPI.error);
                }else{
                  console.log("OKEOKE")
                  var pathName = "/asesmen?evid="+dataUser.id+"X"+respon.data.id+"X"+makeid(6);
                  //this.props.navigate
                //   this.props.history.push({
                //     pathname: pathName,
                //   });
                  window.location.href = pathName;
                }
              })
        .catch(e=>{
            alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
        })
    }
    toggle(stname){
        this.setState({
          [stname]: !this.state[stname],
        });
      }
    submitUploadMateri(){
        if(!this.state.materi){
            alert("upload materi dulu");
            return;
        }
        this.setState({loading:true})
        var dataUser = JSON.parse(localStorage.getItem("data"));
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ id_trainer: dataUser.id, materi: this.state.materi })
          };
          fetch(process.env.REACT_APP_URL_API+'/app_trainer_submit_materi.php', requestOptions)
              .then(response => response.json())
              .then(respon => {
                var dataAPI = respon;
                if(dataAPI.error){
                    alert(dataAPI.error);
                }else{
                    localStorage.setItem("data", JSON.stringify(dataAPI.data));
                    this.setState({dataUser: dataAPI.data});
                    this.cekPengerjaanAsemen();
                }
              })
        .catch(e=>{
            alert(e);
        })
        .finally(()=>{
            this.setState({loading:false})
        })
    }
    prepareAsesmen(){
        var dataUser = JSON.parse(localStorage.getItem("data"));
        var udah_punya_sertif = dataUser.no_sertif != undefined && dataUser.no_sertif != null && dataUser.no_sertif != "";
        //var udah_upload_materi = dataUser.materi != undefined && dataUser.materi != null && dataUser.materi != "";

        if(udah_punya_sertif){
            alert("Anda Sudah Memiliki Sertifikat, Silahkan Unduh dari menu 'Lihat Sertifikat'");
            return;
        }

        // if(!udah_upload_materi){
        //     this.setState({modal:true});
        //     return;
        // }

        this.cekPengerjaanAsemen();
        
    }
    menuAction(id){
        if(id==1){
            this.prepareAsesmen();
            return;
        }

        this.setState({innerPage: id})
    }
    handlePageChange(pageNumber) {
        // console.log(`active page is ${pageNumber}`);
        var x = this.state.itemPerPage;
        var dataAll = this.state.narsumCert;
        var dtRender = dataAll.slice((pageNumber*x)-x,pageNumber*x);
        this.setState({activePage: pageNumber, narsumCertRender: dtRender});
      }
    getList(flag=0){
        var val;
        if(flag==0){
            val = "";
        }else{
            val = document.getElementById("src_narsum").value;
        }
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ src: val })
        };
        fetch(process.env.REACT_APP_URL_API+'/app_portal_narsum.php', requestOptions)
            .then(response => response.json())
            .then(respon => {
            var dataAPI = respon;
            if(dataAPI.error){
                this.sendNotif(dataAPI.error, 'error');
            }else{
                var dtRend = dataAPI.data.slice(0,this.state.itemPerPage);
                this.setState({ narsumCert: dataAPI.data, narsumCertRender: dtRend });
            }});
    }
  render() {
    const {is_login, nama, email, dataUser, innerPage} = this.state;
    const innerElmt = [null, null, <UpdateProfilPage/>, (<SertifikatPage/>), <PengaturanPage/>]
    return (
        
        <div>
        {modalLoading(this.state.loading)}
        <Navbar light expand className={bem.b('bg-primary')}>
            <Nav navbar className="mr-2">
                <img  style={{height:"40px"}} src={logo_kominfo_white}></img>&nbsp;&nbsp;
                <Label style={{color:"white", fontSize:20, alignSelf: "end"}}>Sertifikasi Literasi Digital</Label>
            </Nav>
            <Nav navbar>
            </Nav>
            <Nav navbar className={bem.e('nav-right')}>
                <div style={{display:(is_login)?'block':'none'}}>
                    <NavLink id="Popover2">
                        <Avatar
                            onClick={this.toggleUserCardPopover}
                            className="can-click"
                        />
                    </NavLink>
                    <Popover
                        placement="bottom-end"
                        isOpen={this.state.isOpenUserCardPopover}
                        toggle={this.toggleUserCardPopover}
                        target="Popover2"
                        className="p-0 border-0"
                        style={{ minWidth: 250 }}
                    >
                    <PopoverBody className="p-0 border-light">
                        <UserCard
                        title={dataUser['nama_lengkap']}
                        subtitle={dataUser['email']}
                        //text="Last updated 3 mins ago"
                        className="border-light"
                        >
                        <ListGroup flush>
                            <ListGroupItem tag="button" action className="border-light" onClick={this.submitSignout}>
                                <MdExitToApp /> Signout
                            </ListGroupItem>
                        </ListGroup>
                        </UserCard>
                    </PopoverBody>
                    </Popover>
                </div>
            </Nav>
        </Navbar>
    
        <Row>
            <Col md={9}>
                <Card style={{display:!(is_login)?"block":"none"}}>
                    <CardHeader>Welcome</CardHeader>
                    <CardBody>
                        <Alert color="primary">
                            <Typography type="h4" className="alert-heading">

                            </Typography>
                            <Typography>
                            Sertifikasi Pembicara Kegiatan Indonesia Makin Cakap Digital (IMCD) merupakan upaya yang dilakukan Kementerian Komunikasi dan Informatika RI didukung oleh GNLD Siberkreasi untuk mempersiapkan Pembicara yang kompeten dalam berbagai kegiatan IMCD melalui asesmen yang mengacu pada 4 Pilar Literasi Digital.
                            </Typography>
                            <hr />
                            <Typography className="mb-0" style={{fontSize:12}}>
                            "Don't stop learning because life doesn't stop teaching." Oscar Auliq-Ice
                            </Typography>
                        </Alert>
                    </CardBody>
                </Card>
            </Col>
            <Col md={3}>
                <Card style={{display:!(is_login)?"block":"none"}}>
                    <CardHeader>Login</CardHeader>
                    <CardBody>
                    <Form 
                        className="bg-main"
                        onSubmit={this.submitLogin}>
                            <br/>
                            <p style={{color:'red'}}>{this.state.message}</p>
                            <hr/>
                            <FormGroup>
                            {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
                            <Input name="userid" 
                                type={"text"} name = {"email"}
                                placeholder="your email"
                                style={{fontSize:14}}
                                onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                            {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
                            <InputGroup>
                                <Input 
                                    type={this.state.isViewPwd?"text":"password"}
                                    name="password" 
                                    style={{fontSize:14}}
                                    placeholder="your password"
                                    onChange={this.handleInputChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                                <InputGroupAddon addonType="append">
                                <Button 
                                    size="sm"
                                    onClick={()=>{
                                    this.setState({isViewPwd:!this.state.isViewPwd});
                                    }}
                                    color="primary"
                                    outline={this.state.isViewPwd?true:false}>
                                    {this.state.isViewPwd?<MdVisibilityOff/>:<MdVisibility/>}</Button>
                                </InputGroupAddon>
                            </InputGroup>
                            </FormGroup>

                            <hr />
                            <Button
                                size="sm"
                                color="primary"
                                //className="btn btn-info bg-main-sikocak"
                                //block
                                id="loginBtn"
                                onClick={this.submitLogin}>
                                Login
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>

        <div style={{display:(is_login)?'block':'none'}}>
        <Modal
            isOpen={this.state.modal}
            toggle={()=>{this.toggle('modal')}}
        >
            
            <ModalHeader>Upload Materi</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Label style={{fontSize:12, fontStyle:'italic', fontWeight:'bold'}}>Pilih Salah Satu! Unggah file ke sistem, atau jika anda mengunggah file diluar sistem silahkan inputkan tautan link ke form dibawah</Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label style={{fontSize:12}}>Upload File :</Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <iframe id="viewer" 
                            height="100%"
                            //key={this.state.keyIframe}
                            src={this.state.urlIFrame}
                        />
                    </Col>
                    <Col sm={9}>
                        <Input
                            name={"materi"}
                            type={"file"}
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <Label style={{fontSize:12}}>Tautan Link :</Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Input
                            name={"materi"}
                            type={"text"}
                            onChange={this.handleInputChange}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button style={{width:"100%"}} onClick={()=>{this.submitUploadMateri()}}>Submit</Button>
            </ModalFooter>
        </Modal>
        <Row style={{marginTop:10,}}>
            <Col md={12} hidden={innerPage==0?true:false}>
                <Button style={{margin:15}} outline color="primary"
                    onClick={()=>{this.setState({innerPage:0})}}
                ><MdKeyboardBackspace/>&nbsp;&nbsp;Kembali ke Menu Utama</Button>
            </Col>
            <Col md={9}>
                {(innerPage==0)?
                    <Card>
                        <CardHeader>Menu Utama</CardHeader>
                        <CardBody>
                            {menu_utama.map((x,index)=>(
                                <Button style={{width:200}} outline color="link" key={index}
                                    name={x.name} onClick={()=>{this.menuAction(x.id)}}
                                >
                                    <img style={{height:100}} src={x.src} />
                                    <Label>{x.text}</Label>
                                </Button>
                            ))}
                        </CardBody>
                    </Card>
                    :
                    innerElmt[innerPage]
                }
                
            </Col>
            <Col md={3}>
                <Card>
                    <CardBody>
                        <ReactPlayer url='https://youtu.be/flYIt-SEGpg' width="100%" height="100%"/>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </div>

        <Row style={{marginTop:10}}>
        <Modal
            isOpen={this.state.modalNarsum}
            toggle={()=>{this.toggle('modalNarsum')}}
            style={{maxWidth:'60%'}}
        >
            <ModalHeader>Narasumber Tersertifikasi</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Form inline className="cr-search-form" onSubmit={(e)=>{e.preventDefault(); this.getList(1)}}>
                            <MdSearch
                                size="20"
                                className="cr-search-form__icon-search text-secondary"
                            />
                            <Input
                                type="search"
                                className="cr-search-form__input"
                                name="src"
                                id="src_narsum"
                                placeholder="Search..."
                                onChange={this.handleInputChange}
                            />&nbsp;&nbsp;
                            <Button size="md" onClick={()=>{this.getList(1)}}>Search</Button>
                            &nbsp;&nbsp;
                            <Button size="md" color="warning" onClick={()=>{this.setState({src:''},()=>{this.getList(0)})}}>Reset</Button>
                        </Form>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Table style={{fontSize:10}} size="sm" responsive {...{ ['' || 'default']: true }}>
                            <thead>
                                <tr>
                                    <th style={{width:"13px"}}>No</th>
                                    <th>Nama</th>
                                    <th>Nomor Sertifikat</th>
                                    <th>Komunitas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.narsumCertRender.map((dt,index)=>(
                                <tr style={{backgroundColor:(index%2==0)?"#eff4fc":"#fff"}} key={index}>
                                    <td scope="row">{dt.n}</td>
                                    <td>{dt.nama_lengkap}</td>
                                    <td>{dt.no_sertif}</td>
                                    <td>{dt.lembaga}</td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                
            </ModalBody>
            <ModalFooter>
                <Col className="d-flex justify-content-between">
                    &nbsp;
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        // hideNavigation={true}
                        // hideFirstLastPages={true}
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemPerPage}
                        totalItemsCount={this.state.narsumCert.length}
                        pageRangeDisplayed={10}
                        onChange={this.handlePageChange.bind(this)}
                    />
                    &nbsp;
                </Col>
            </ModalFooter>
        </Modal>
            <Col md={6}>
                <Card>
                    <CardHeader className="d-flex justify-content-between">
                        Modul 4 Pilar LIterasi Digital&nbsp;
                        <Button 
                            color="link"
                            size="sm"
                            href="https://literasidigital.id/buku"
                            target="_blank"
                        >
                            Lihat Lainnya
                        </Button>
                    </CardHeader>
                    <CardBody>
                        {books.map((x,index)=>(
                            <a title="Click to Open in New Tab" style={{margin:10}} href={x.href} key={index} target="_blank">
                                <img src={x.src} style={{width:'20%'}}/>
                            </a>
                        ))}
                    </CardBody>
                </Card>
            </Col>

            <Col md={3}>
                <Card>
                    <CardHeader className="d-flex justify-content-between">Narasumber Tersertifikasi
                        &nbsp;
                        <Button size="sm" color="primary" outline
                            onClick={()=>{
                                this.getList(0);
                            }}
                        ><MdRefresh/></Button>
                    </CardHeader>
                    <CardBody>
                        <Table style={{fontSize:10}} size="sm" responsive {...{ ['' || 'default']: true }}>
                            <thead>
                                <tr>
                                    <th style={{width:"13px"}}>No</th>
                                    <th>Nama</th>
                                    <th>Nomor Sertifikat</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.narsumCert.slice(0,10).map((dt,index)=>(
                                <tr style={{backgroundColor:(index%2==0)?"#eff4fc":"#fff"}} key={index}>
                                    <td scope="row">{index+1}</td>
                                    <td>{dt.nama_lengkap}</td>
                                    <td>{dt.no_sertif}</td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                        <hr/>
                        <Button size="sm"
                            onClick={()=>{
                                this.setState({modalNarsum:true})
                            }}
                        >Lihat Selengkapnya</Button>
                    </CardBody>
                </Card>
            </Col>

            <Col md={3}>
                <Card>
                    <CardBody>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={5000}
                            showThumbs={false}
                            showStatus={false}
                            //emulateTouch={true}
                            //onClickItem={(itm)=>{this.handleClickImage(itm)}}
                            style={{height:480,widht:300}}
                        >
                        {
                            images.map((each, index) => 
                            <div key={index}>
                            {each.link!=''?<a style={{
                                width:'100%',
                                height:'100%',
                                zIndex:3,
                                position:'absolute'
                            }} href={each.link}>&nbsp;</a>:null}
                            <img src={each.src}/>
                                
                            </div>
                            )
                        }
                        </Carousel>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </div>
    );
  }
  sendNotif(msg, lvl){
    this.notificationSystem.addNotification({
        title: <MdWarning />,
        message: msg,
        level: lvl,
      });
  }
}

export default PortalPage;
