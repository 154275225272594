import logo200Image from 'assets/img/logo/logo_200.png';
import {modalLoading} from '../assets/custom/element'
import PropTypes from 'prop-types';
import md5 from 'md5';
import React from 'react';
import {encrypt} from '../utils/func/auth_func'
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import {
  MdDelete,MdCloudUpload,MdWarning,MdEdit,MdCheckCircle,MdSearch,
  MdRemoveRedEye,MdVisibilityOff,MdVisibility,MdAccountCircle
} from 'react-icons/md';

class AuthForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        userid:null,
        password:false,
        data: null,
        message: null,
        isViewPwd:false,
        modal:false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("admin"))
      window.location.href = "/dashboard"
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    this.setState({
      [target.name]: value
    });
  }
  handleKeyDown(event){
    if(event.keyCode === 13){
      event.preventDefault();
      //console.log(this.state.search)
      this.handleSubmit(event);
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.setState({modal:true})
    const userid = this.state.userid;
    const password = this.state.password;
    const enc_pass = encrypt(password);
    //console.log(enc_pass);
    //return;
    var IsLogin = false;
    //console.log(userid + ", " + password)
    const requestOptions = {
      method: 'POST',
      // headers: { 'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin':'*',
      // 'access-control-allow-headers':'Authorization, Content-Type' },
      body: JSON.stringify({ username: userid, password: enc_pass })
    };
    fetch(process.env.REACT_APP_URL_API+'/adm_login.php', requestOptions)
        .then(response => response.json())
        .then(respon => {
          var dataAPI = respon;
          if(dataAPI.error){
            this.setState({ message: dataAPI.error });
          }else{
            localStorage.setItem("token", dataAPI.token);
            localStorage.setItem("name", dataAPI.name);
            localStorage.setItem("username", dataAPI.username);
            localStorage.setItem("admin", true);
            var now = new Date().getTime();
            localStorage.setItem('setupTime', now);
            IsLogin = true;
            //window.location.href = "/dashboard";
            //return <Redirect to="/dashboard" />
          }})
          .then(()=>{
            //console.log(IsLogin);
            this.setState({modal:false})
            if(IsLogin){
              //this.props.history = window.location.origin;
              this.props.history.push('/dashboard');
            }
            
          });
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Form 
      className="bg-main"
      onSubmit={this.handleSubmit}>
        <div className="text-center pt-1">
          <MdAccountCircle 
            style={{ width: 210, height: 62, cursor: 'pointer', color:'#28ae9c' }}/>
        </div>
        <div className="text-center pt-1">
          <h3>Administrator Login</h3>
          {/* <h6>Aplikasi </h6> */}
        </div>
        <br/>
        <p style={{color:'red'}}>{this.state.message}</p>
        <hr/>
        {modalLoading(this.state.modal)}
        <FormGroup>
          {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
          <Input name="userid" onChange={this.handleInputChange} {...usernameInputProps} onKeyDown={this.handleKeyDown} />
        </FormGroup>
        <FormGroup>
          {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
          <InputGroup>
            <Input 
              type={this.state.isViewPwd?"text":"password"}
              name="password" 
              placeholder="your password"
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown} />
            <InputGroupAddon addonType="append">
              <Button 
                onClick={()=>{
                  var oldState = this.state.isViewPwd;
                  this.setState({isViewPwd:!oldState});
                }}
                color="primary"
                outline={this.state.isViewPwd?true:false}>
                {this.state.isViewPwd?<MdVisibilityOff/>:<MdVisibility/>}</Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} />
          </FormGroup>
        )}

        <hr />
        <div className="text-center pt-1">
          <Button
            size="lg"
            color="primary"
            //className="btn btn-info bg-main-sikocak"
            //block
            id="loginBtn"
            onClick={this.handleSubmit}>
            Login
          </Button>
        </div>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'User ID',
  usernameInputProps: {
    type: 'text',
    placeholder: 'your user id',

  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default withRouter(AuthForm);
