import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Card, Col, Row, CardBody,
    CardTitle, CardText, Button,Badge,Table,
    Input,Label,FormGroup, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import Countdown from 'react-countdown';
import {
    MdBubbleChart,
    MdInsertChart,
    MdPersonPin,
    MdPieChart,
    MdRateReview,
    MdShare,
    MdShowChart,MdWarning, MdCheckCircle,
    MdFullscreen,
    MdApps,MdClear,
    MdAdd,MdFilterList,
    MdLibraryAdd,MdContentCopy,MdAccountCircle,
  } from 'react-icons/md';
import { getTodayDate, logout_trainer } from '../../utils/func/global_func';
import { modalLoading, modalWarnReloadAses } from '../../assets/custom/element';
import { intlDateTimeFormatSupported } from 'javascript-time-ago';
import { decodePDFRawStream, NumberParsingError } from 'pdf-lib';

var base64 = require('base-64');
const form_regis = [
    {label: "Nama Lengkap", type: "text", id: "nama_lengkap", placeholder: "Nama Lengkap (Tanpa Gelar)", style: {fontSize:14}, col:12},
    {label: "Nomor Telepon", type: "text", id: "no_telp", placeholder: "Nomor telepon yang terhubung Whatsapp (jika ada)", style: {fontSize:14}, col:12},
    {label: "Email", type: "text", id: "email", placeholder: "alamat email", style: {fontSize:14}, col:12},
    {label: "Komunitas/Lembaga/Institusi", type: "text", id: "lembaga", placeholder: "asal komunitas/lembaga/institusi", style: {fontSize:14}, col:12},
]

const form_post_asesmen = [
    {label: "Di antara empat pilar literasi digital, pilar yang paling saya kuasai adalah", type: "radio", id: "answ_q1", placeholder: "",style: {fontSize:14}},
    {label: "Di antara empat pilar literasi digital, pilar yang saya butuh pendalaman lebih lanjut adalah", type: "radio", id: "answ_q2", placeholder: "",style: {fontSize:14}},
    {label: "Tema yang saya kuasai di masing-masing pilar sebagai berikut (tema boleh lebih dari satu di setiap pilar):", type: "table", id: "tema_dikuasai", 
        placeholder: "",style: {fontSize:14}, formH:[{label: "Pilar"},{label: "Tema yang Dikuasai"},],
        formB:[
            {label: "Kecakapan Digital", id:'kecakapan'},
            {label: "Etis Bermedia Digital", id:'etis'},
            {label: "Budaya  Digital", id:'budaya'},
            {label: "Keamanan Digital", id:'keamanan'},
        ],
    },
    {label: "Masukan atau saran saya terhadap empat pilar kurikulum literasi digital yang ada saat ini adalah", type: "textarea", id: "answ_q4", placeholder: "",style: {fontSize:14,height:100}},
]

const pilihan_tema = [
    {label: "Kecakapan Digital", id:'kecakapan'},
    {label: "Etis Bermedia Digital", id:'etis'},
    {label: "Budaya  Digital", id:'budaya'},
    {label: "Keamanan Digital", id:'keamanan'},
];

class AsesmenPage extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            token: localStorage.getItem("token"),
            evid:null,
            preases:false,
            lquest_preases:[],
            lanswer:[],
            submitted: false,
            modal:false,
            cek_email_data: {},
            cek_email_data2:{},
            state_questions: [],
            active_question:0,
            dataUser: {},
            max_nQuest: 43,
            poin:0,
            stepper: [true,false,false,false],
            loading: false,
            timer:2400,
            timePerSoal:60,
            warn:false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        
    }
    componentWillUnmount(){
        localStorage.setItem("timer",undefined);
    }
    componentDidMount() {
        // this is needed, because InfiniteCalendar forces window scroll
        window.scrollTo(0, 0);

        this.getEventDetail();
        this.setStateQuest(0);
        this.getPreases();
        //prevent back browser
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });

        //read last timer before refresh
        if(localStorage.getItem("timer")){
            
            this.setState({
                timer: parseInt(localStorage.getItem("timer")),
                lquest_preases: JSON.parse(localStorage.getItem("lquest_preases")),
                max_nQuest: parseInt(localStorage.getItem("max_nQuest")),
                stepper: [false,true,false,false],
            }, ()=>{
                console.log("timer terakhir : "+ this.state.timer)
                console.log("timer terakhir : "+ this.state.timer)
                this.startCount();
            })
        }else{
            this.getPreases();
        }

        
        
        //asking before refresh page
        window.onbeforeunload = function () {
            return false};

        //saving to local storage when page reloaded
        window.onunload = function() {
            localStorage.setItem("timer", document.getElementById("timerCount").textContent);
            localStorage.setItem("stepper", JSON.stringify(this.state.stepper));
        };

        
        
    }
    getEventDetail(){
        this.setState({loading: true});
        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get("evid");

        
        if(paramValue==null){
            this.props.history.push('/');
            return;
        }
        var ids = paramValue.split("X");
        var id_trainer = ids[0];
        var id_event = ids[1];
        
        
        this.setState({id_trainer: id_trainer, id_event: id_event});
        //console.log("event id: "+paramValue);

        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+localStorage.getItem("token"),
              },
            body: JSON.stringify({ id_trainer: id_trainer, id_event: id_event })
          };
        fetch(process.env.REACT_APP_URL_API+'/app_asesmen_validasi.php', requestOptions)
            .then(response => response.json())
            .then(respon => {
                var dataAPI = respon;
                if(dataAPI.error){
                    //this.sendNotif(dataAPI.error, 'error');
                    alert(respon.error);
                    logout_trainer();
                    return;
                }else{
                    this.setState({
                    dataUser: respon.data
                    })
            }})
            .catch(e => {
                alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const key = target.name;
        
        if(target.type=="file"){
          this.setState({[key]:''});
          //this.getFile(event.target.files[0],key);
        }
        //console.log(value);
        this.setState({
          [key]: value
        })
    }
    
    setStateQuest(idx, is_next=false){
        const {max_nQuest} = this.state;

        // if(is_next){
        //     if(!this.confirmNextQuestion()){
        //         alert("Pilih Jawaban Terlebih Dahulu Sebelum Klik Pertanyaan Selanjutnya");
        //         this.setState({active_question: this.state.active_question - 1});
        //         //console.log(this.state.active_question);
        //         return;
        //     }
        // }
        

        if(idx == max_nQuest) return;
        var arr = [];
        for(var x = 0; x < max_nQuest; x++){
            if(x == idx){
                arr.push(true);    
            }else{
                arr.push(false);
            }
        }
        this.setState({state_questions: arr});
    }
    confirmNextQuestion(){ //sebelum pindah ke pertanyaan selanjutnya harus isi dulu
        const {lquest_preases} = this.state;

        var n_act = this.state.active_question-1;
        var id = lquest_preases[n_act].id;
        //console.log("confirm next:" + (this.state.active_question-1));
        var jawaban = this.state['quest_'+id];
        if(jawaban==undefined){
            return false;
        }

        return true;
    }
    confirmSemuaJawaban(){
        const {lquest_preases, max_nQuest} = this.state;
        for(var x = 0; x < lquest_preases.length; x++){
            var id = lquest_preases[x].id;
            var jawaban = this.state['quest_'+id];
            if(jawaban==undefined){
                return false;
            }
        }
        return true;
    }

    sumNilai(){
        this.setState({loading:true});
        const {lquest_preases, max_nQuest} = this.state;

        var n_benar = 0;
        for(var x = 0; x < lquest_preases.length; x++){
            var id = lquest_preases[x].id;
            var jawaban = this.state['quest_'+id];
            var l = lquest_preases[x];
            l.choice.map(x=>{
                if(x.id==jawaban){
                    //console.log(x.flag==1?"BENAR":"SALAH")
                    if(x.flag==1){
                        n_benar++;
                        
                    }
                }
            })
        }

        console.log(n_benar);
        var poin = Math.round(n_benar/max_nQuest*100);
        this.setState({poin: poin},()=>{
            this.submit(poin);
        });
    }

    getPreases(){
        this.setState({loading:true});
        const requestOptions = {
            method: 'POST',
          };
        fetch(process.env.REACT_APP_URL_API+'/app_quest_ases_get.php', requestOptions)
            .then(response => response.json())
            .then(respon => {
                var dataAPI = respon;
                if(dataAPI.error){
                    //this.sendNotif(dataAPI.error, 'error');
                    alert(respon.error);
                    this.props.history.push('/');
                    return;
                }else{
                    
                    var encodedDataSoal = dataAPI.data;
                    var decodedDataSoal = base64.decode(encodedDataSoal);
                    var JsonData = JSON.parse(decodedDataSoal);
                    //console.log("encode : "+encodedDataSoal);
                    //console.log("decode : "+decodedDataSoal);
                    //console.log(JsonData.data);
                    this.setState({
                        lquest_preases: JsonData.data, max_nQuest: JsonData.data.length, timer: JsonData.data.length * this.state.timePerSoal
                    })
                    
                    localStorage.setItem("lquest_preases", JSON.stringify(JsonData.data));
                    localStorage.setItem("max_nQuest", JsonData.data.length);
                }})
            .catch(e => {
                alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }
    submit(poin){
        const {id_trainer,id_event} = this.state;
        var dtUser = JSON.parse(localStorage.getItem("data"));

        var body_req = {};
        body_req.id_trainer = id_trainer;
        body_req.id_event   = id_event;
        body_req.poin       = poin;
        body_req.tanggal    = getTodayDate();
        body_req.flag_lolos = poin>70?true:false;

        for(var x = 0; x < form_post_asesmen.length; x++){
            if(form_post_asesmen[x].type=="table"){
                for(var y = 0; y < form_post_asesmen[x].formB.length; y++){
                    var id_state = form_post_asesmen[x].formB[y].id;
                    var state_val = this.state[id_state];
                    
                    body_req[id_state] = state_val;
                }
            }else{
                var id_state = form_post_asesmen[x].id;
                var state_val = this.state[id_state];
                
                body_req[id_state] = state_val;
            }
        }

        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(body_req)
        };
        fetch(process.env.REACT_APP_URL_API+'/app_asesmen_submit.php', requestOptions)
            .then(response => response.json())
            .then(respon => {
                var dataAPI = respon;
                this.setState({stepper:[false,false,false,true], loading:false});
                document.getElementById("step3").style['display'] = 'none';
                document.getElementById("step4").style['display'] = 'block';
                if(dataAPI.error){
                    //this.sendNotif(dataAPI.error, 'error');
                    alert(respon.error);
                    this.props.history.push('/');
                    return;
                }else{
                    // alert(respon.success);
                    // this.setState({token_asesmen: respon.token, submitted: true});
                    localStorage.setItem("data", JSON.stringify(dataAPI.data));
                }
            })
            .catch(e => {
                alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
                this.setState({loading: false});
            })
            .finally(()=>{
                this.setState({loading: false});
            })
    }
    toggle(){
        this.setState({
          modal: !this.state.modal,
        });
      }
    startCount(){
        var time = this.state.timer+1;
        //var time = 50;
        var x = setInterval(function(){
            time = time - 1;
            var minutes = Math.floor((time % (1 * 60 * 60)) / (1 * 60));
            var seconds = Math.floor((time % (1 * 60)) / 1);
            // if(window.clipboardData){
            //     window.clipboardData.clearData();
            // }
            
            if (window.clipboardData && window.clipboardData.setData) {
                // IE: prevent textarea being shown while dialog is visible
                return window.clipboardData.setData("Text", null);
            
              }
            //navigator.clipboard.writeText('');
            //window.clipboardData.clearData();
            var spanM = (minutes < 10) ? ("0"+minutes) : minutes;
            var spanD = (seconds < 10) ? ("0"+seconds) : seconds;
            document.getElementById("timerM").textContent = spanM;
            document.getElementById("timerD").textContent = spanD;
            document.getElementById("timerCount").textContent = time;
            if(minutes <= -1 && seconds <= -1){
                var masihStep2 = document.getElementById("step2").style['display'] == 'block';
                if(masihStep2){
                    //alert("Waktu Anda sudah habis");
                    
                    document.getElementById("kontenWarnWaktuHabis").style['display'] = 'block';
                    document.getElementById("kontenSoal").style['display'] = 'none';
                }
                clearInterval(x);
            }

        },1000)
    }
    
  render() {
      const {id,nama,dataUser,tanggal,lokasi, lquest_preases, token_asesmen, 
        stepper, cek_email_data2,
        code, cek_email_msg, state_questions, max_nQuest} = this.state;
    return (
        <div id="mainDiv" style={{userSelect:'none'}}>
            {modalLoading(this.state.loading)}
            
            <Row
                style={{
                background: 'linear-gradient(rgb(193 249 254), rgb(1 75 131))',
                justifyContent: 'center',
                }}>
                <Col md={9} lg={9}>
                    <Card body
                        className="border-0 bg-gradient-theme card text-white"
                    >
                        <CardBody className="d-flex flex-column justify-content-start align-items-start">
                            <CardTitle>ASESMEN : </CardTitle>
                            <CardTitle style={{fontSize:30}}>{dataUser.nama}</CardTitle>
                        </CardBody>
                        <CardBody className="d-flex justify-content-between align-items-center">
                            <CardText>Nama : {dataUser.nama_lengkap}</CardText>
                            <Badge color="light" pill className="mr-1">
                                tanggal: {getTodayDate()}
                            </Badge>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div id={'step1'} 
                style={{display: (stepper[0])?'block':'none'}}
            >
            <Row
                style={{
                    justifyContent: 'center'
                }}
            >
                <Col md={9} lg={9}>
                    <Card body style={{marginTop:15, marginBottom:15}}>
                        <CardBody className="d-flex flex-column justify-content-start align-items-start">
                            <CardTitle style={{fontSize:20}}>Pengantar</CardTitle>
                            <CardText style={{fontSize:12}}>Dalam rangka {dataUser.nama}, Tim Literasi Digital Kominfo telah menyusun asesmen untuk sertifikasi ulang narasumber. Hasil asesmen akan menjadi salah satu penilaian kelayakan Bapak/Ibu untuk menjadi narasumber.</CardText>
                            <CardText style={{fontSize:12}}>
                                <Label sm={12}> - Terdapat {max_nQuest} soal pilihan ganda</Label>
                                {/* <Label sm={12}> - Terdapat 4 pertanyaan asesmen diri</Label> */}
                                {/* <Label sm={12}> - Ambang batas kelulusan adalah 70</Label> */}
                                <Label sm={12}> - Batas waktu pengerjaan Asemen adalah 
                                    <Label style={{fontWeight:'bold'}}>&nbsp;{Math.floor(this.state.timer / 60)} menit {(this.state.timer) % 60} detik ({this.state.timePerSoal} detik per soal)</Label>
                                </Label>
                                {/* <Label sm={12}> - Anda diberikan 2 kali kesempatan mengerjakan asesmen</Label> */}
                                {/* <Label sm={12}> - Batas maksimal pengerjaan asesmen adalah 1 kali dalam 1 hari (jika nilai kurang dari ambang batas maka dapat mengerjakan ulang asesmen keesokan hari)</Label> */}
                                <Label sm={12}> - Anda tidak boleh keluar dari halaman selama mengerjakan asesmen</Label>
                                <Label sm={12}> - Anda hanya memiliki satu kali kesempatan untuk mengerjakan asesmen</Label>
                                <Label sm={12}> - Pastikan koneksi internet anda stabil !</Label>
                                
                            </CardText>
                            <CardTitle style={{fontSize:16}}>Selamat Mengerjakan! Semoga Sukses!</CardTitle>
                        </CardBody>
                    </Card>
                    <Button
                        size="sm"
                        style={{fontSize:14, marginLeft:15 ,marginBottom: 15}}
                        onClick={()=>{
                            this.setState({stepper: [false,true,false,false]});
                            this.startCount();
                        }}
                    >
                        Mulai Mengerjakan
                    </Button>
                </Col>
            </Row>
            </div>
            <div id={'step2'}
                style={{display: (stepper[1])?'block':'none'}}
            >
            <Row
                id="kontenWarnWaktuHabis" style={{
                    justifyContent: 'center', display:"none"
                }}
                //className="d-flex flex-column justify-content-center align-items-center"
            >
                <Col md={12} lg={12} className="d-flex flex-column justify-content-center align-items-center">
                    <Row>
                        <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
                            <h2 style={{marginTop:15, marginLeft:15}}>Waktu Habis</h2>
                            <h6 style={{marginTop:15, marginLeft:15}}>Jawaban anda akan otomatis tersubmit dan asesmen dianggap selesai</h6>
                        </Col>
                    </Row>
                    <Button
                        size="sm"
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{
                            document.getElementById("step4").style['display'] = 'block';
                            document.getElementById("step2").style['display'] = 'none';
                            this.sumNilai();
                            this.setState({stepper:[false,false,false,true]});
                        }}
                    >
                        Lanjutkan
                    </Button>
                </Col>
            </Row>
            <Row
                id="kontenSoal"
                style={{
                    justifyContent: 'center',
                }}
            >                        
                <Col md={9} lg={9}>
                    <Row>
                        <Col md={10}>
                            <h2 style={{marginTop:15, marginLeft:15}}>Asesmen</h2>
                            <h6 style={{marginTop:15, marginLeft:15}}>Pertanyaan ke {this.state.active_question+1} dari {max_nQuest}</h6>
                        </Col>
                        <Col md={2} className="d-flex flex-column justify-content-center align-items-end">
                            {/* <Countdown
                                date={Date.now() + this.state.timer}
                                renderer={this.rendererCountDown}
                                daysInHours={true}
                            /> */}
                            <Label
                                style={{fontSize:30, marginRight:10}}
                            >
                                <Label id="timerM">00</Label>:<Label id="timerD" >00</Label>&nbsp;
                                <Label id="timerCount" hidden={true}></Label>
                            </Label>
                        </Col>
                    </Row>
                    {/* <Row className="d-flex flex-column justify-content-center align-items-center"> */}
                    <Row>
                        <Col md={3} style={{paddingTop:"15px"}}>
                            {lquest_preases.map((x,index)=>(
                                <Button 
                                    size="sm" 
                                    outline={this.state['quest_'+x.id]?false:true} 
                                    color="success" 
                                    style={{width:40, backgroundColor:"white"}}
                                    onClick={()=>{
                                        this.setState({active_question: index}, ()=>{
                                            this.setStateQuest(this.state.active_question);
                                        });
                                    }}
                                
                                >{index+1}</Button>
                            ))}
                        </Col>
                    {/* </Row> */}
                    
                    <Col md={9}>
                    {lquest_preases.map((x,index)=>(
                        <Card key={index} style={{margin:15, display:(this.state.state_questions[index]?'block':'none')}}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.pertanyaan}</CardText>
                            </CardBody>
                            {x.src_img==null?null:
                                <CardBody>
                                    <img src={process.env.REACT_APP_URL_API+"/soal/"+x.src_img}></img>
                                </CardBody>
                            }
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {(x.tipe != '3')?
                                    <Input
                                        style={{fontSize:14}}
                                        type={x.data_type}
                                        name={'quest_'+x.id}
                                        id={'quest_'+x.id}
                                        placeholder={"jawaban"}
                                        onChange={this.handleInputChange}
                                    />
                                    :
                                    <Col>
                                        {x.choice.map(y=>(
                                            <FormGroup check key={y.id} style={{background:(this.state['quest_'+x.id]==y.id)?'rgb(50 164 225 / 25%)':'rgb(50 164 225 / 12%)', margin:2, borderRadius:4}}>
                                                <Label check style={{width:"100%"}}>
                                                    <div style={{padding:10}}>
                                                    <Input
                                                        style={{fontSize:14}}
                                                        type={x.data_type}
                                                        name={'quest_'+x.id}
                                                        id={y.id}
                                                        value={y.id}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {y.answer}
                                                    </div>
                                                </Label>
                                                
                                            </FormGroup>
                                        ))}
                                    </Col>
                                }
                            </CardBody>
                        </Card>
                        
                    ))}
                    <Button
                        size="sm"
                        outline
                        hidden={(this.state.active_question==0)?true:false}
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{
                            this.setState({active_question: this.state.active_question - 1}, ()=>{
                                this.setStateQuest(this.state.active_question);
                            });
                        }}
                    >
                        Sebelumnya
                    </Button>
                    <Button
                        size="sm"
                        hidden={(this.state.active_question==this.state.max_nQuest-1)?true:false}
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{
                            this.setState({active_question: this.state.active_question + 1}, ()=>{
                                this.setStateQuest(this.state.active_question, true);
                            });
                        }}
                    >
                        Selanjutnya
                    </Button>
                    <Button
                        size="sm"
                        hidden={!(this.state.active_question==this.state.max_nQuest-1)?true:false}
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{
                            if(!this.confirmSemuaJawaban()){
                                alert("Masih ada soal yang belum terjawab, silahkan selesaikan terlebih dahulu")
                                return;
                            }
                            //window.confirm("apakah anda yakin")
                            this.sumNilai();
                            this.setState({stepper:[false,false,false,true]});
                        }}
                    >
                        SUBMIT
                    </Button>
                    </Col>
                    </Row>
                </Col>
            </Row>
            </div>
            {/* ##### STEP 3 DI SKIP ##### */}
            <div id={'step3'}
                style={{ display: (stepper[2])?'block':'none'}}
            >
            <Row
                style={{
                    justifyContent: 'center'
                }}
            >
                <Col md={9} lg={9}>
                <h2 style={{marginTop:15, marginLeft:15}}>Asesmen Diri</h2>
                {form_post_asesmen.map(x=>(
                        <Card style={{margin:15}}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.label}</CardText>
                            </CardBody>
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {x.type=="table"?
                                    <Col>
                                        <Table style={{fontSize:10}} size="sm" responsive {...{ ['' || 'default']: true }}>
                                        <thead>
                                            <tr>
                                            <th style={{width:"13px"}}>No</th>
                                            {x.formH.map(y=>(
                                                <th>{y.label}</th>
                                            ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {x.formB.map((dt,index)=>(
                                            <tr style={{backgroundColor:(index%2==0)?"#eff4fc":"#fff"}} key={index}>
                                                <td scope="row">{index+1}</td>
                                                <td>{dt.label}</td>
                                                <td scope="row">{
                                                    <Input
                                                        style={{fontSize:14}}
                                                        type={"text"}
                                                        name={dt.id}
                                                        id={dt.id}
                                                        onChange={this.handleInputChange}
                                                    />
                                                }</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        </Table>
                                    </Col>
                                    :
                                    (
                                        x.type=="radio"?
                                        <Col>
                                            {pilihan_tema.map((z,index)=>(
                                                <FormGroup check key={z.id}>
                                                    <Label check>
                                                        <Input
                                                            style={{fontSize:14}}
                                                            type={x.type}
                                                            name={x.id}
                                                            id={x.id}
                                                            value={z.label}
                                                            onChange={this.handleInputChange}
                                                        />
                                                        {z.label}
                                                    </Label>
                                                </FormGroup>
                                            ))}
                                        </Col>
                                        :
                                        <Input
                                            style={x.style}
                                            type={x.type}
                                            name={x.id}
                                            id={x.id}
                                            placeholder={x.placeholder}
                                            onChange={this.handleInputChange}
                                        />
                                    )
                                }
                                
                            </CardBody>
                        </Card>
                    ))}
                    {/* <Button
                        size="sm"
                        outline
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{
                            this.setState({stepper:[false,true,false,false]});
                        }}
                    >
                        Sebelumnya
                    </Button> */}
                    <Button
                        size="sm"
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{
                            var ctr_ = 0;
                            for(var x = 0; x < form_post_asesmen.length; x++){
                                if(form_post_asesmen[x].type=="table"){
                                    for(var y = 0; y < form_post_asesmen[x].formB.length; y++){
                                        var id_state = form_post_asesmen[x].formB[y].id;
                                        var state_val = this.state[id_state];
                                        if(state_val==undefined){
                                            alert("Isi terlebih dahulu Tema yang Dikuasai");
                                            ctr_++;
                                            break;
                                        }
                                    }
                                }else{
                                    var id_state = form_post_asesmen[x].id;
                                    var state_val = this.state[id_state];
                                    if(state_val==undefined){
                                        alert("Isi terlebih dahulu Asesmen Diri");
                                        ctr_++;
                                        break;
                                    }
                                }
                            }
                            if(ctr_==0){
                                
                                this.sumNilai();
                            }
                            
                        }}
                    >
                        Submit
                    </Button>
                </Col>
            </Row>
            </div>
            {/* ##### STEP 3 DI SKIP ##### */}
            <div id={'step4'}
                style={{ display: (stepper[3])?'block':'none'}}
            >
            <Row
                style={{
                    justifyContent: 'center'
                }}
            >
                <Col md={9} lg={9}>
                    <Card body style={{marginTop:15}}>
                        <CardBody className="d-flex flex-column justify-content-start align-items-start">
                            <CardTitle style={{fontSize:20}}>Anda telah menyelesaiakan asesmen !</CardTitle>
                            <CardText style={{fontSize:12}}>Berikut nilai asesmen anda:</CardText>
                            <CardTitle>Nilai Asesmen : </CardTitle>
                            <CardTitle style={{fontSize:30}}>{this.state.poin}</CardTitle>
                            <CardText style={{fontSize:12}}>{
                                 this.state.poin > 70 ?
                                 "Selamat, nilai anda memenuhi ambang batas, silahkan unduh sertifikat anda melalui menu 'Lihat Sertifikat'":
                                 "Nilai anda kurang dari ambang batas"
                                //"Informasi Sertifikat akan diberitahukan lebih lanjut melalui email"
                            }</CardText>
                        </CardBody>
                    </Card>
                    <Button
                        size="sm"
                        style={{fontSize:14, marginTop: 15, marginBottom: 15}}
                        onClick={()=>{
                            window.location.href = "/";
                        }}
                    >
                        Kembali ke Menu Utama
                    </Button>
                </Col>
            </Row>
            </div>
        </div>
    );
  }
  sendNotif(msg, lvl){
    this.notificationSystem.addNotification({
        title: <MdWarning />,
        message: msg,
        level: lvl,
      });
  }
}

export default AsesmenPage;
