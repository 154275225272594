import React from 'react';
import {encrypt} from '../../utils/func/auth_func'
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Row, Col,
    Card,CardHeader,CardBody,CardText,
    Input, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import {
  MdDelete,MdCloudUpload,MdWarning,MdEdit,MdCheckCircle,MdSearch,
  MdRemoveRedEye,MdVisibilityOff,MdVisibility,MdAccountCircle
} from 'react-icons/md';
import { logout_trainer } from '../../utils/func/global_func';


class PengaturanPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        userid:null,
        password:false,
        data: null,
        message: null,
        isViewPwd:false,
        modal:false,
        fileName:"",
        isEdit:false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;
    
    if(target.type=="file"){
      //this.setState({[key]:event.target.files[0]});
      this.uploadFile(event.target.files[0]);
    }
    //console.log(value);
    this.setState({
      [key]: value
    })
  }

  savePassword(){
    var dataUser = JSON.parse(localStorage.getItem("data"));
    const {password} = this.state;
    const formUrl = process.env.REACT_APP_URL_API+'/app_portal_newpwd.php'
    fetch(formUrl, {
      method: 'POST',
      body: JSON.stringify({id_trainer: dataUser.id, new_password: encrypt(password) })
    }).then(response => response.json())
    .then(respon => {
      var dataAPI = respon;
      if(dataAPI.error){
        alert(dataAPI.error);
      }else{
        alert("Password Berhasil Diubah, Silahkan Login Ulang");
        logout_trainer();
      }
    });
  }
  
  render() {
    const {isEdit} = this.state;
    const dataUser = JSON.parse(localStorage.getItem("data"));
    return (
      <div>
        <Card>
            <CardHeader>Pengaturan Akun</CardHeader>
            <CardBody>
              <Row>
                  <Col style={{fontSize:14}}>
                    <FormGroup row>
                      <Label sm={3}>
                        Email
                      </Label>
                      <Col sm={6}>
                        <Input
                          readOnly={true}
                          type="text"
                          name="name"
                          id="name"
                          value={dataUser.email}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={3}>
                        Password
                      </Label>
                      <Col sm={6}>
                        {isEdit?
                            <div>
                                <Input
                                    style={{marginBottom:5}}
                                    type="text"
                                    name="password"
                                    id="password"
                                    placeholder="ketikkan password baru"
                                    onChange={this.handleInputChange}
                                />
                                <Button size="sm"
                                    onClick={()=>{this.savePassword()}}
                                >Simpan</Button>&nbsp;
                                <Button color="danger" size="sm"
                                    onClick={()=>{
                                        this.setState({isEdit:!this.state.isEdit});
                                    }}
                                >Batal</Button>
                            </div>
                            :
                            <div>
                                *****************&nbsp;
                                <Button color="link" size="sm" onClick={()=>{
                                    this.setState({isEdit:!this.state.isEdit});
                                }}>Ganti Password</Button>
                            </div>
                        }
                        
                      </Col>
                    </FormGroup>
                  </Col>
              </Row>
            </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(PengaturanPage);
