import React from 'react';
import { Route } from 'react-router-dom';

// const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => (
//   <Route
//     {...rest}
//     render={props => (
//       <Layout>
//         <Component {...props} />
//       </Layout>
//     )}
//   />
// );

class LayoutRoute extends React.Component {
  render() {
    const { Component, Layout, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
}

export default LayoutRoute;
