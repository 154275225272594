import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout, FullLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';

import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

import AuthPage from 'pages/AuthPage';
import RegisEventPage from './pages/front/RegisEvent';
import AsesmenPage from './pages/front/Asesmen';
import PortalPage from './pages/front/PortalPage';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const Adm_EventPage = React.lazy(() => import('pages/Adm_Event'));
const Adm_Template = React.lazy(() => import('pages/Adm_Template'));
const Adm_Trainers = React.lazy(() => import('pages/Adm_Trainers'));
const Adm_Mailer = React.lazy(() => import('pages/Adm_Mailer'));
// const PortalPage = React.lazy(() => import('pages/front/PortalPage'));
// const RegisEventPage = React.lazy(() => import('pages/front/RegisEvent'));
// const AsesmenPage = React.lazy(() => import('pages/front/Asesmen'));
// const AuthPage = React.lazy(() => import('pages/AuthPage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const Setting = React.lazy(() => import('pages/Setting'));
const MyTaskPage = React.lazy(() => import('pages/MyTaskPage'));
const LabelPage = React.lazy(() => import('pages/LabelPage'));
const LabeledPage = React.lazy(() => import('pages/LabeledPage'));
const TaskPage = React.lazy(() => import('pages/TaskPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  componentDidMount() {
    //this.cekSession();
    //this.expireSession();
  }
  componentWillUpdate(){
    //this.expireSession();
    //this.cekSession();
  }
  cekSession(){
    var curLoc = window.location.href;
    var origin = window.location.origin;
    var curPath = curLoc.replace(origin,'');
    var session = localStorage.getItem("user_session");
    if(curPath != "/"){
      if(session==null){
        window.location.href = "/";
        // console.log("Redir: " + origin + curLoc)
        // console.log(curLoc)
      }
    }
  }
  expireSession(){
    var hours = 1; // Reset when storage is more than 1hours
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        localStorage.setItem('setupTime', now);
        console.log("setupTime : " + now);
        //window.location.href = "/";
    } else {
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear();
            console.log("logout");
            //localStorage.setItem('setupTime', now);
            window.location.href = "/";
        }
    }
  }
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            {/* <FullLayout>
              <Route exact path="/admin" component={AuthPage} key={"admin"} />
            </FullLayout>
            <FullLayout>
              <Route exact path="/regisevent" component={RegisEventPage} key={"regisevent"} />
            </FullLayout>
            <FullLayout>
              <Route exact path="/asesmen" component={AsesmenPage} key={"asesmen"}/>
            </FullLayout>         
            <FullLayout>
              <Route exact path="/" component={PortalPage}  key={"portal"}/>
            </FullLayout> */}
            
            
            {/* <LayoutRoute
              exact
              path="/admin"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            /> */}

            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <PortalPage {...props} />
              )}
            />

            <LayoutRoute
              exact
              path="/admin"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/regisevent"
              layout={EmptyLayout}
              component={props => (
                <RegisEventPage {...props} />
              )}
            />
            <LayoutRoute
              exact
              path="/asesmen"
              layout={EmptyLayout}
              component={props => (
                <AsesmenPage {...props} />
              )}
            />
            

            <MainLayout breakpoint={this.props.breakpoint} id="main">
              <React.Suspense fallback={<PageSpinner />}>
                <Route exact path="/dashboard" component={DashboardPage}  key={"dashboard"}/>
                <Route exact path="/adm_event" component={Adm_EventPage}  key={"adm_event"}/>
                <Route exact path="/adm_template" component={Adm_Template} key={"adm_template"} />
                <Route exact path="/adm_trainers" component={Adm_Trainers}  key={"adm_trainers"}/>
                <Route exact path="/adm_mailer" component={Adm_Mailer} />
                <Route exact path="/setting" component={Setting}  key={"setting"} />
                {/* <Route exact path="/form" component={FormPage} /> */}

                {/* <Route exact path="/" component={(routeProps)=> <PortalPage layout="kosong" {...routeProps}/>}  key={"portal"} /> */}
              </React.Suspense>
            </MainLayout>
            
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
