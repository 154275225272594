
import {
    Button,Card,CardBody,CardHeader,Col,
    Form,FormFeedback,FormGroup, InputGroup, InputGroupAddon,
    FormText,Input,Label,Row,Table,Modal,ModalBody, ModalFooter, ModalHeader
  } from 'reactstrap';
import React from 'react';
import loadingImg from 'assets/img/logo/loading.gif';
import { func } from 'prop-types';

export const modalLoading = (state, customText = "Loading") =>{
    return <Modal
      style={{
        position: 'absolute',
        float: 'left',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      isOpen={state}
      >
  
      <ModalBody>
        <img src={loadingImg}></img>
        <br/>
        {customText}
      </ModalBody>
    </Modal>
  }

  export const modalWarnReloadAses = (state, customText = "Jika anda melnjutkan untuk refresh halaman ini maka asesmen akan otomatis tersubmit dan dianggap selesai. Tekan cancel untuk membatalkan") =>{
    return <Modal
      style={{
        position: 'absolute',
        float: 'left',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      isOpen={state}
      >
  
      <ModalBody>
        <img src={loadingImg}></img>
        <br/>
        {customText}
      </ModalBody>
    </Modal>
  }

export const modalInsertPerngajuanBerkas = (state) => {
    return <Modal
        id={"modalInsertPengajuan"}
        isOpen={state}
        //toggle={state}
        //backdrop={"static"}
        >
        <ModalHeader>
            Modal title
        </ModalHeader>
        <ModalBody>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
            occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={()=>{state=false}}>
                Do Something
            </Button>{' '}
            <Button color="secondary" onClick={()=>{
                
            }}>
                Cancel
            </Button>
        </ModalFooter>
  </Modal>
}