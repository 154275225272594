import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import md5 from 'md5';
import React from 'react';
import {encrypt} from '../../utils/func/auth_func'
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, 
    Card,CardHeader,CardBody,CardText,
    Input, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import {
  MdDelete,MdCloudUpload,MdWarning,MdEdit,MdCheckCircle,MdSearch,
  MdRemoveRedEye,MdVisibilityOff,MdVisibility,MdAccountCircle
} from 'react-icons/md';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import templatePDF from 'pdf-templater';
import { saveAs } from 'file-saver';
import { modalLoading } from '../../assets/custom/element';
const download = require('downloadjs');


class SertifikatPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        userid:null,
        password:false,
        data: null,
        message: null,
        isViewPwd:false,
        modal:false,
        fileName:"",
        downloading:false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    //this.getTemplateSertif();
  }

  getTemplateSertif(id_state){
    var msg = "";
    this.setState({downloading:true})
    var dataUser = JSON.parse(localStorage.getItem("data"));
    const formUrl = process.env.REACT_APP_URL_API+'/app_portal_get_template_filename.php'

    var id_event = id_state==1 ? dataUser.id_event : dataUser.id_event_2022;
    fetch(formUrl, {
      method: 'POST',
      body: JSON.stringify({id_event: id_event })
    })
    .then(response => response.json())
    .then(respon => {
      //this.setState({downloading:false})
      var dataAPI = respon;
      if(dataAPI.error){
        alert(dataAPI.error);
      }else{
        //console.log(respon);
        this.setState({fileName: respon.data.filename});
      }
    })
    .catch(e => {
      msg = e;
    })
    .finally(()=>{
        this.setState({downloading: false});
    })
    return msg;
  }

  async generateSertif(id_state) {
    this.setState({downloading:true});
    if(this.state.fileName==""){
      var msg = await this.getTemplateSertif(id_state);
      if(msg!=""){
        alert(msg);
      }
    }
    try{
      var dataUser = JSON.parse(localStorage.getItem("data"));
      const formUrl = process.env.REACT_APP_URL_API+'/download_file.php'
      const formPdfBytes = await fetch(formUrl, {
        method: 'POST',
        body: JSON.stringify({fileName: this.state.fileName })
      }).then(res => res.arrayBuffer())
  
      //console.log(formPdfBytes);
      //return;
      // Load a PDF with form fields
      const pdfDoc = await PDFDocument.load(formPdfBytes)
  
      // Get the form containing all the fields
      const form = pdfDoc.getForm();
  
      // Fill the form's fields
      var no_sertif = id_state == 1 ? dataUser.no_sertif : dataUser.no_sertif_2022;
      form.getTextField('nama').setText(dataUser.nama_lengkap.toUpperCase());
      form.getTextField('no_sertif').setText(no_sertif);
  
      // Flatten the form's fields
      form.flatten();
  
      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();
  
      // Trigger the browser to download the PDF document
      download(pdfBytes, "your certificate.pdf", "application/pdf");
    }catch(err){
      alert("Generate sertifikat gagal, silahkan coba beberapa saat lagi, (pastikan koneksi internet anda stabil)" + err);
    }finally{
      this.setState({downloading:false})
    }
    
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;
    
    if(target.type=="file"){
      //this.setState({[key]:event.target.files[0]});
      this.uploadFile(event.target.files[0]);
    }
    //console.log(value);
    this.setState({
      [key]: value
    })
  }
  
  render() {
    const {downloading} = this.state;
    const dataUser = JSON.parse(localStorage.getItem("data"));
    return (
      <div>
        {modalLoading(this.state.downloading)}
        <Card>
            <CardHeader>Sertifikat Anda</CardHeader>
            <CardBody>
              <CardText style={{fontSize:14}}>Sertifikat : {dataUser.is_can_download==1?(dataUser.no_sertif||"(Anda belum memiliki sertifikat)"):"(Anda belum memiliki sertifikat)"}</CardText>
                <Button
                    hidden={dataUser.is_can_download==1?(dataUser.no_sertif?false:true):true} //kalo bisa download cek lagi (kalo ada sertif => bisa donlot)
                    onClick={()=>{this.generateSertif(1)}}
                    disabled={downloading}
                >
                    {downloading?'Downloading...':'Download Sertifikat'}
                </Button>
                <br/>
                <CardText style={{fontSize:14}}>Sertifikat TOT 2022 : {dataUser.no_sertif_2022||"(Anda belum memiliki sertifikat)"}</CardText>
                <Button
                    hidden={dataUser.no_sertif_2022?false:true}
                    onClick={()=>{this.generateSertif(2)}}
                    disabled={downloading}
                >
                    {downloading?'Downloading...':'Download Sertifikat'}
                </Button>
                
            </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(SertifikatPage);
