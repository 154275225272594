import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-11.jpg';
import SourceLink from 'components/SourceLink';
import '../../styles/custom/custom.css'
import React from 'react';
import { FaDiceD20 } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navComponents = [
  // { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
  // {
  //   to: '/button-groups',
  //   name: 'button groups',
  //   exact: false,
  //   Icon: MdGroupWork,
  // },
  // { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
  // { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
  // {
  //   to: '/dropdowns',
  //   name: 'dropdowns',
  //   exact: false,
  //   Icon: MdArrowDropDownCircle,
  // },
  // { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
  // { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
  // { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
  // { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
];

const navContents = [
  // { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  // { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
];

const pageContents = [
  // { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  // {
  //   to: '/login-modal',
  //   name: 'login modal',
  //   exact: false,
  //   Icon: MdViewCarousel,
  // },
];

const navItems = [
  { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
  // { to: '/cards', name: 'cards', exact: false, Icon: MdWeb },
  { to: '/adm_event', name: 'Kegiatan Training', exact: false, Icon: MdInsertChart },
  { to: '/adm_template', name: 'Template Sertifikat', exact: false, Icon: MdInsertChart },
  { to: '/adm_trainers', name: 'Narasumber', exact: false, Icon: MdInsertChart },
  //{ to: '/label', name: 'label', exact: false, Icon: MdWidgets },
];
const navItemsEO = [
  { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
  // { to: '/cards', name: 'cards', exact: false, Icon: MdWeb },
  //{ to: '/adm_event', name: 'Kegiatan Training', exact: false, Icon: MdInsertChart },
  //{ to: '/adm_template', name: 'Template Sertifikat', exact: false, Icon: MdInsertChart },
  { to: '/adm_trainers', name: 'Narasumber', exact: false, Icon: MdInsertChart },
  //{ to: '/label', name: 'label', exact: false, Icon: MdWidgets },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        token: localStorage.getItem("token"),
        isAdmin: localStorage.getItem("admin")=='1',
        name: localStorage.getItem("name"),                   
        isEO: localStorage.getItem("name")!='Kominfo', 
    };
  }
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  componentDidMount(){

  }
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    const {isAdmin, isEO} = this.state;
    return (
      // <aside className={bem.b()} data-image={sidebarBgImage}>
      //   <div className={bem.e('background')} style={sidebarBackground} />
      <aside className={bem.b()}>
        <div className={bem.e('background')}  />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              {/* <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              /> */}
              <span className="text" style={{fontSize:18}}>
                Sertifikasi Admin
              </span>
            </SourceLink>
          </Navbar>
          <Nav style={{display:isEO?"block":"none"}} vertical>
            {navItemsEO.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  style={{color:"#000"}}
                  id={`navItem-${name}-${index}`}
                  //className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="sideItm">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <Nav style={{display:!isEO?"block":"none"}} vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  style={{color:"#000"}}
                  id={`navItem-${name}-${index}`}
                  //className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="sideItm">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
