import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import validator from 'validator';
import React from 'react';
import { Card, Col, Row, CardBody,
    CardTitle, CardText, Button,Badge,
    Input,Label,FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table
} from 'reactstrap';
import {
    MdBubbleChart,
    MdInsertChart,
    MdPersonPin,
    MdPieChart,
    MdRateReview,
    MdShare,
    MdShowChart,MdWarning, MdCheckCircle,
    MdFullscreen,
    MdApps,MdClear,
    MdAdd,MdFilterList,
    MdLibraryAdd,MdContentCopy,MdAccountCircle,
  } from 'react-icons/md';
import { encrypt } from '../../utils/func/auth_func';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { modalLoading } from '../../assets/custom/element';
const form_regis = [
    {label: "Nama Lengkap", type: "text", id: "nama_lengkap", placeholder: "Nama Lengkap (Tanpa Gelar)", mandatory: true,style: {fontSize:14}, col:12},
    {label: "Nomor Telepon", type: "text", id: "no_telp", placeholder: "Nomor telepon yang terhubung Whatsapp (jika ada)", mandatory: true, style: {fontSize:14}, col:12},
    {label: "Email", type: "text", id: "email", placeholder: "alamat email", mandatory: true, style: {fontSize:14}, col:12},
    {label: "Jenis Kelamin", type: "radio", id: "jenis_kelamin", placeholder: "", mandatory: true, choice: [
        {id:1, text: "Laki-Laki"},{id:2, text: "Perempuan"},
    ]},
    {label: "Tanggal Lahir", type: "date", id: "tgl_lahir", mandatory: true, placeholder: "", style: {fontSize:14}, col:12},
    {label: "Pendidikan Terakhir", type: "text", id: "pendidikan", mandatory: false, placeholder: "Pendidikan Terakhir", style: {fontSize:14}, col:12},
    {label: "Asal Provinsi", type: "text", id: "prov", placeholder: "Asal Provinsi", mandatory: false, style: {fontSize:14}, col:12},
    {label: "Asal Kota", type: "text", id: "kota", placeholder: "Asal Kota", mandatory: false, style: {fontSize:14}, col:12},
    {label: "Jabatan dan Organisasi/Institusi", type: "textarea", id: "lembaga", mandatory: false, placeholder: "(Boleh diisi lebih dari 1)\n1. \n2.", style: {fontSize:14, height:100}, col:12},
    {label: "Akun Media Sosial", type: "textarea", id: "medsos", mandatory: false, placeholder: "IG : \nFB : \nTwitter : \nLinkedin : \n(Lainnya) :", style: {fontSize:14, height:100}, col:12},
]

const form_pengalaman = [
    {label: "Pengalaman Menjadi Narasumber terkait Literasi Digital (5 tahun terakhir)", type: "table", id: "pengalaman_narsum", 
    placeholder: "",style: {fontSize:14}, form:[
        {label: "Nama Kegiatan",id:"p_nama_kegiatan"},{label: "Daring/Luring", id:"p_tipe_kegiatan"},{label: "Penyelenggara", id:"p_penyelenggara"},{label: "Tahun Kegiatan", id:"p_tahun"}
    ],
    countRow:[1,2,3,4,5]},
    {label: "Pengalaman lainnya terkait Literasi Digital", type: "table", id: "pengalaman_lainnya", 
    placeholder: "",style: {fontSize:14}, form:[
        {label: "Deskripsi", id:"p_desc"}
    ],
    countRow:[1,2,3]},
    {label: "Upload CV", type: "file", id: "cv", placeholder: "",style: {fontSize:14}},
]
class RegisEventPage extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            token: localStorage.getItem("token"),
            evid:null,
            preases:false,
            lquest_preases:[],
            submitted: false,
            email:"",
            modal:false,
            cek_email_data: {},
            cek_email_data2:{},
            step:[true,false,false],
            default_password:"kominfo",
            link_portal:process.env.REACT_APP_URL,
            loading:false,

        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        // this is needed, because InfiniteCalendar forces window scroll
        window.scrollTo(0, 0);
        this.getEventDetail();
        this.getPreases();
    }
    getEventDetail(){
        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get("evid");

        if(paramValue==null){
            this.props.history.push('/');
            return;
        }
        var ids = paramValue.split("_");
        var id_event = ids[0];
        this.setState({evid: id_event});
        //console.log("event id: "+paramValue);

        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify({ id: id_event })
          };
        
        fetch(process.env.REACT_APP_URL_API+'/app_kegiatan_regis.php', requestOptions)
                .then(response => response.json())
                .then(respon => {
                    var dataAPI = respon;
                    if(dataAPI.error){
                    //this.sendNotif(dataAPI.error, 'error');
                    alert(respon.error);
                    this.props.history.push('/');
                    return;
                    }else{
                    this.setState({
                        id: respon.data.id,
                        nama: respon.data.nama,
                        deskripsi: respon.data.deskripsi,
                        tanggal: respon.data.tanggal,
                        lokasi: respon.data.lokasi,
                        created_date: respon.data.created_date
                    })
                    }
                })
                .catch(err => {
                    alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
                });
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const key = target.name;
        
        if(target.type=="file"){
          //this.setState({[key]:event.target.files[0]});
          this.uploadFile(event.target.files[0]);
        }
        //console.log(value);
        this.setState({
          [key]: value
        })
      }
    openStep(idx){
        var temp = [false,false,false];
        temp[idx] = true;
        this.setState({step: temp});
    }
    nextStep(){
        const {email, evid} = this.state;
        if (!validator.isEmail(email)) {
            alert('Email yang anda masukkan tidak valid');
            return;
        }else{
            this.setState({email: email.toLowerCase()})
        }

        

        var state = 0;
        var n = form_regis.length;
        for(var i=0;i<n;i++){
            var elmt = form_regis[i].id;
            if(form_regis[i].mandatory){
                if(this.state[elmt]==undefined){
                    state++;
                    alert("Lengkapi Data "+form_regis[i].label);
                    break;
                }
            }
            
        }
        if(state==0){
            //cek email apakah sudah pernah digunakan?
            const requestOptions = {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify({ id_event: evid, email: email })
              };
            fetch(process.env.REACT_APP_URL_API+'/app_trainer_regis_cek.php', requestOptions)
                  .then(response => response.json())
                  .then(respon => {
                    var dataAPI = respon;
                    console.log(respon);
                    if(dataAPI.error){
                        this.setState({
                            code: respon.code, cek_email_msg: respon.error, 
                            cek_email_data: respon.data||{}, cek_email_data2: respon.data2||{},
                            modal:true,
                        });
                        this.openStep(0);//error maka balik lagi ke step data diri
                        //this.setState({preases:false});            
                        return;
                    }else{
                        
                    }});
            this.openStep(1); //next step
            //this.setState({preases:true});
        }
        
    }
    getPreases(){
        const requestOptions = {
            method: 'POST',
          };
        fetch(process.env.REACT_APP_URL_API+'/app_quest_preases_get.php', requestOptions)
              .then(response => response.json())
              .then(respon => {
                var dataAPI = respon;
                if(dataAPI.error){
                  //this.sendNotif(dataAPI.error, 'error');
                  alert(respon.error);
                  //this.props.history.push('/');
                  return;
                }else{
                  this.setState({
                    lquest_preases: dataAPI.data
                  })
                }});
    }
    submit(){
        this.setState({loading:true});
        const {lquest_preases,evid,default_password,cv} = this.state;
        var answers = [];
        var body_regis = {};

        var cpreases = lquest_preases.length;
        for(var i = 0; i < cpreases; i++){
            var anw = {};
            anw.id_answ = null;
            anw.id_quest = lquest_preases[i].id;
            anw.jawaban = this.state['quest_'+lquest_preases[i].id];
            //if(anw.jawaban == undefined){
                //alert("Jawab Terlebih Dahulu Semua Pertanyaan Preasesmen!");
                //break;
            //}else{
                if(lquest_preases[i].tipe=='3'){
                    anw.id_answ = this.state['quest_'+lquest_preases[i].id];
                    anw.jawaban = '';
                }
            //}
            
            answers.push(anw);
        }
        
        form_pengalaman.map(x=>{
            if(x.type=="table"){
                var list = [];
                x.countRow.map(y=>{
                    var peng = {};
                    x.form.map(z=>{
                        var val = this.state[z.id+"_"+y];
                        if(val==undefined){
                            val="";
                        }
                        peng[z.id] = val;
                    })
                    list.push(peng);
                })
                body_regis[x.id] = list;
            }
        })
        
        form_regis.map(x=>{
            var elmt = x.id;
            body_regis[elmt] = this.state[elmt]||"";
        })
        body_regis.answers = answers;
        body_regis.id_event = evid;
        body_regis.password = encrypt(default_password);
        body_regis.cv = cv;

        
        //console.log(this.state.cv);
        //console.log(body_regis);
        //return;
        
        const requestOptions = {
            //ContentType: 'multipart/form-data',
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(body_regis)
        };
        fetch(process.env.REACT_APP_URL_API+'/app_trainer_regis.php', requestOptions)
                .then(response => response.json())
                .then(respon => {
                    var dataAPI = respon;
                    this.setState({loading:false});
                    if(dataAPI.error){
                    //this.sendNotif(dataAPI.error, 'error');
                    alert(respon.error);
                    this.props.history.push('/');
                    return;
                    }else{
                        alert(respon.success);
                        var def = "kominfo";
                        if(respon.is_trainer_baru == false){
                            def = "(karena anda bukan user baru, gunakan password lama anda)"
                        }
                        this.setState({token_asesmen: respon.token, submitted: true, default_password: def});
                    }})
                .catch(err => {
                    alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
                    this.setState({loading:false});
                });
    }
    uploadFile(file){
        this.setState({loading:true});
        const data = new FormData();
        data.append('cv',file);

        const requestOptions = {
            ContentType: 'multipart/form-data',
            mode: 'cors',
            method: 'POST',
            body: data
        };
        fetch(process.env.REACT_APP_URL_API+'/app_trainer_upload_cv.php', requestOptions)
                .then(response => response.json())
                .then(respon => {
                    var dataAPI = respon;
                    this.setState({loading:false});
                    if(dataAPI.error){
                    alert(respon.error);
                    return;
                    }else{
                        this.sendNotif("File berhasil diupload","success");
                        var srcIframe = process.env.REACT_APP_URL_API+"/upload/"+respon.fileName;
                        this.setState({cv:respon.fileName, urlIFrame: srcIframe});
                        
                        console.log(respon.fileName)
                    }})
                .catch(err => {
                    alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
                    this.setState({loading:false});
                });
    }
    toggle(){
        this.setState({
          modal: !this.state.modal,
        });
      }
  render() {
      const {id,nama,deskripsi,tanggal,lokasi,email,default_password,link_portal,
        created_date, lquest_preases, token_asesmen, 
        cek_email_data, cek_email_data2,
        code, cek_email_msg, step} = this.state;
    return (
        <div>
            <NotificationSystem
                dismissible={false}
                ref={notificationSystem =>
                    (this.notificationSystem = notificationSystem)
                }
                style={NOTIFICATION_SYSTEM_STYLE}
            />
            {modalLoading(this.state.loading)}
            <Row
                style={{
                background: 'linear-gradient(rgb(193 249 254), rgb(1 75 131))',
                justifyContent: 'center',
                }}>
                <Col md={6} lg={6}>
                    <Card body
                        className="border-0 bg-gradient-theme card text-white"
                    >
                        <CardBody className="d-flex flex-column justify-content-start align-items-start">
                            <CardTitle>Registrasi Kegiatan : </CardTitle>
                            <CardTitle style={{fontSize:30}}>{nama}</CardTitle>
                            <CardText style={{fontSize:10}}>{deskripsi}</CardText>
                        </CardBody>

                        <CardBody className="d-flex justify-content-between align-items-center">
                            <CardText>Lokasi: {lokasi}</CardText>
                            <Badge color="light" pill className="mr-1">
                                tanggal: {tanggal}
                            </Badge>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div 
                style={{display: (this.state.submitted)?'none':'block'}}
            >
            <Row
                style={{
                    justifyContent: 'center',
                }}
            >
                <Modal
                    isOpen={this.state.modal}
                    toggle={()=>{this.toggle()}}
                    className={this.props.className}>
                    <ModalHeader toggle={()=>{this.toggle()}}>Email sudah digunakan</ModalHeader>
                    <ModalBody style={{fontSize:12}}>
                    <Row>
                        <Col>
                            <Label>{cek_email_msg}</Label>
                        </Col>
                    </Row>
                    <Row style={{display:(code)?'block':'none'}}>
                        <Col>
                            <Label sm={3}>Nama</Label>
                            <Label sm={7}>{cek_email_data["nama_lengkap"]}</Label>
                        </Col>
                        <Col>
                            <Label sm={3}>Nomor Telepon</Label>
                            <Label sm={7}>{cek_email_data["no_telp"]}</Label>
                        </Col>
                        <Col>
                            <Label sm={3}>Kegiatan</Label>
                            <Label sm={7}>{cek_email_data2["nama"]}</Label>
                        </Col>
                        <Col>
                            <Label>Apakah anda ingin melanjutkan dengan akun tersebut?</Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button size="sm"
                                color="danger"
                                style={{fontSize:14}}
                                onClick={()=>{
                                    this.toggle();
                                }}
                            >
                                Kembali
                            </Button>&nbsp;
                            <Button 
                                color="success"
                                size="sm"
                                style={{fontSize:14, display:(code)?'block':'none'}}
                                onClick={()=>{
                                    this.openStep(2);
                                    this.toggle();
                                }}
                            >
                                Lanjutkan
                            </Button>
                        </Col>
                    </Row>
                    </ModalBody>
                </Modal>
                <Col md={6} lg={6} 
                    //Col registration DATA DIRI : STEP 0
                    style={{
                        display: (step[0])?'block':'none'
                    }}
                >
                    <h2 style={{marginTop:15, marginLeft:15}}>Data Diri</h2>
                    {form_regis.map(x=>(
                        <Card style={{margin:15}}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.label}</CardText>
                            </CardBody>
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {x.type=="radio"?
                                    <Col>
                                        {x.choice.map(y=>(
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        style={{fontSize:14}}
                                                        type={"radio"}
                                                        name={x.id}
                                                        id={y.id}
                                                        value={y.id}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {y.text}
                                                </Label>
                                            </FormGroup>
                                        ))}
                                    </Col>
                                    :
                                    <Input
                                        style={x.style}
                                        type={x.type}
                                        name={x.id}
                                        id={x.id}
                                        placeholder={x.placeholder}
                                        onChange={this.handleInputChange}
                                    />
                                }
                                
                            </CardBody>
                        </Card>
                    ))}
                    <Button
                        size="sm"
                        style={{fontSize:14, marginLeft:15, marginBottom:15}}
                        onClick={()=>{this.nextStep()}}
                    >
                        Selanjutnya
                    </Button>
                </Col>

                <Col md={6} lg={6} 
                    //Col registration Pengalaman : STEP 2
                    style={{
                        display: (step[1])?'block':'none'
                    }}
                >
                    <h2 style={{marginTop:15, marginLeft:15}}>Pengalaman</h2>
                    {form_pengalaman.map(x=>(
                        <Card style={{margin:15}}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.label}</CardText>
                            </CardBody>
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {x.type=="table"?
                                    <Col>
                                        <Table style={{fontSize:10}} size="sm" responsive {...{ ['' || 'default']: true }}>
                                        <thead>
                                            <tr>
                                            <th style={{width:"13px"}}>No</th>
                                            {x.form.map(y=>(
                                                <th>{y.label}</th>
                                            ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {x.countRow.map((dt,index)=>(
                                            <tr style={{backgroundColor:(index%2==0)?"#eff4fc":"#fff"}} key={index}>
                                                <td scope="row">{dt}</td>
                                                {x.form.map(y=>(
                                                    <td scope="row">{
                                                        <Input
                                                            style={{fontSize:14}}
                                                            type={"text"}
                                                            name={y.id+"_"+dt}
                                                            id={y.id+"_"+dt}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    }</td>
                                                ))}
                                            </tr>
                                            ))}
                                        </tbody>
                                        </Table>
                                    </Col>
                                    :
                                    <div>
                                        {x.type=="file"?
                                        <iframe id="viewer" 
                                            height="100%"
                                            //key={this.state.keyIframe}
                                            src={this.state.urlIFrame}
                                        />
                                        :null}
                                    <Input
                                        style={x.style}
                                        type={x.type}
                                        name={x.id}
                                        id={x.id}
                                        placeholder={x.placeholder}
                                        onChange={this.handleInputChange}
                                    />
                                    </div>
                                }
                                
                            </CardBody>
                        </Card>
                    ))}
                    <Button
                        size="sm"
                        outline
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{this.openStep(0)}}
                    >
                        Sebelumnya
                    </Button>
                    <Button
                        size="sm"
                        style={{fontSize:14, marginLeft:15, marginBottom:15}}
                        //onClick={()=>{this.openStep(2)}}
                        onClick={()=>{this.submit()}}
                    >
                        Submit
                    </Button>
                </Col>

                        
                <Col md={6} lg={6} 
                    //Col pre asesmen
                    style={{
                        display: (step[2])?'block':'none'
                    }}
                >
                    <h2 style={{marginTop:15, marginLeft:15}}>Pre Asesmen</h2>
                    {lquest_preases.map(x=>(
                        <Card style={{margin:15}}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.pertanyaan}</CardText>
                            </CardBody>
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {(x.tipe != '3')?
                                    <Input
                                        style={{fontSize:14}}
                                        type={x.data_type}
                                        name={'quest_'+x.id}
                                        id={'quest_'+x.id}
                                        placeholder={"jawaban"}
                                        onChange={this.handleInputChange}
                                    />
                                    :
                                    <Col>
                                        {x.choice.map(y=>(
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        style={{fontSize:14}}
                                                        type={x.data_type}
                                                        name={'quest_'+x.id}
                                                        id={y.id}
                                                        value={y.id}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {y.answer}
                                                </Label>
                                            </FormGroup>
                                        ))}
                                    </Col>
                                }
                            </CardBody>
                        </Card>
                    ))}
                    <Button
                        size="sm"
                        outline
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{this.openStep(1)}}
                    >
                        Sebelumnya
                    </Button>
                    <Button
                        size="sm"
                        style={{fontSize:14, marginLeft: 15, marginBottom: 15}}
                        onClick={()=>{this.submit()}}
                    >
                        Simpan
                    </Button>
                </Col>
            </Row>
            </div>
            <div
                style={{ display: !(this.state.submitted)?'none':'block'}}
            >
            <Row
                style={{
                    justifyContent: 'center'
                }}
            >
                <Col md={6} lg={6}>
                    <Card body style={{marginTop:15}}>
                        <CardBody className="d-flex flex-column justify-content-start align-items-start">
                            <CardTitle style={{fontSize:20}}>Registrasi Anda Berhasil !</CardTitle>
                            <CardText style={{fontSize:12}}>Selamat mengikuti kegiatan {nama}. Berikut ini adalah akun default untuk masuk ke web portal Trainers anda</CardText>
                            <CardTitle>Akun Default anda :</CardTitle>
                            <Col sm={12}>
                                <Card color="primary" inverse>
                                    <CardBody>
                                    <Label sm={3}>Email</Label>
                                    <Label sm={9}>: {email}</Label>
                                    <Label sm={3}>Password</Label>
                                    <Label sm={9}>: {default_password}</Label>
                                    </CardBody>
                                </Card>
                            </Col>
                            <br/>
                            <CardText style={{fontSize:10}}>kunjungi link berikut untuk mengakses web portal trainers <a target="_blank" href={link_portal}>(klik disini)</a></CardText>
                            <CardText style={{fontSize:10}}>(segera ganti password default setelah berhasil masuk)</CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </div>
        </div>
      
    );
  }
  sendNotif(msg, lvl){
    this.notificationSystem.addNotification({
        title: <MdWarning />,
        message: msg,
        level: lvl,
      });
  }
}

export default RegisEventPage;
