import React from 'react';
import {encrypt} from '../../utils/func/auth_func'
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Row, Col,
    Card,CardHeader,CardBody,CardText,Table,ButtonGroup,
    Input, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import {
  MdDelete,MdCloudUpload,MdWarning,MdEdit,MdCheckCircle,MdSearch,
  MdRemoveRedEye,MdVisibilityOff,MdVisibility,MdAccountCircle
} from 'react-icons/md';
import { logout_trainer } from '../../utils/func/global_func';
import { modalLoading } from '../../assets/custom/element';

const form_regis = [
    {label: "Nama Lengkap", type: "text", id: "nama_lengkap", placeholder: "Nama Lengkap (Tanpa Gelar)", mandatory: true,style: {fontSize:14}, col:12},
    {label: "Nomor Telepon", type: "text", id: "no_telp", placeholder: "Nomor telepon yang terhubung Whatsapp (jika ada)", mandatory: true, style: {fontSize:14}, col:12},
    //{label: "Email", type: "text", id: "email", placeholder: "alamat email", mandatory: true, style: {fontSize:14}, col:12},
    {label: "Jenis Kelamin", type: "radio", id: "jenis_kelamin", placeholder: "", mandatory: true, choice: [
        {id:1, text: "Laki-Laki"},{id:2, text: "Perempuan"},
    ]},
    {label: "Tanggal Lahir", type: "date", id: "tgl_lahir", mandatory: true, placeholder: "", style: {fontSize:14}, col:12},
    {label: "Pendidikan Terakhir", type: "text", id: "pendidikan", mandatory: false, placeholder: "Pendidikan Terakhir", style: {fontSize:14}, col:12},
    {label: "Asal Provinsi", type: "text", id: "prov", placeholder: "Asal Provinsi", mandatory: false, style: {fontSize:14}, col:12},
    {label: "Asal Kota", type: "text", id: "kota", placeholder: "Asal Kota", mandatory: false, style: {fontSize:14}, col:12},
    {label: "Jabatan dan Organisasi/Institusi", type: "textarea", id: "lembaga", mandatory: false, placeholder: "(Boleh diisi lebih dari 1)\n1. \n2.", style: {fontSize:14, height:100}, col:12},
    {label: "Akun Media Sosial", type: "textarea", id: "medsos", mandatory: false, placeholder: "IG : \nFB : \nTwitter : \nLinkedin : \n(Lainnya) :", style: {fontSize:14, height:100}, col:12},
]

const form_pengalaman = [
    {label: "Pengalaman Menjadi Narasumber terkait Literasi Digital (5 tahun terakhir)", type: "table", id: "pengalaman_narsum", 
    placeholder: "",style: {fontSize:14}, form:[
        {label: "Nama Kegiatan",id:"p_nama_kegiatan"},{label: "Daring/Luring", id:"p_tipe_kegiatan"},{label: "Penyelenggara", id:"p_penyelenggara"},{label: "Tahun Kegiatan", id:"p_tahun"},{label: "id update", id:"id", hide:true}
    ],
    countRow:[1,2,3,4,5]},
    {label: "Pengalaman lainnya terkait Literasi Digital", type: "table", id: "pengalaman_lainnya", 
    placeholder: "",style: {fontSize:14}, form:[
        {label: "Deskripsi", id:"p_desc"},{label: "id update", id:"id_pl", hide:true}
    ],
    countRow:[1,2,3]},
    {label: "Upload CV", type: "file", id: "cv", placeholder: "",style: {fontSize:14}},
]
const form_post_asesmen = [
    {label: "Di antara empat pilar literasi digital, pilar yang paling saya kuasai adalah", type: "radio", id: "answ_q1", placeholder: "",style: {fontSize:14}},
    {label: "Di antara empat pilar literasi digital, pilar yang saya butuh pendalaman lebih lanjut adalah", type: "radio", id: "answ_q2", placeholder: "",style: {fontSize:14}},
    {label: "Tema yang saya kuasai di masing-masing pilar sebagai berikut (tema boleh lebih dari satu di setiap pilar):", type: "table", id: "tema_dikuasai", 
        placeholder: "",style: {fontSize:14}, formH:[{label: "Pilar"},{label: "Tema yang Dikuasai"},],
        formB:[
            {label: "Kecakapan Digital", id:'kecakapan'},
            {label: "Etis Bermedia Digital", id:'etis'},
            {label: "Budaya  Digital", id:'budaya'},
            {label: "Keamanan Digital", id:'keamanan'},
        ],
    },
    //{label: "Masukan atau saran saya terhadap empat pilar kurikulum literasi digital yang ada saat ini adalah", type: "textarea", id: "answ_q4", placeholder: "",style: {fontSize:14,height:100}},
]
const pilihan_tema = [
    {label: "Kecakapan Digital", id:'kecakapan'},
    {label: "Etis Bermedia Digital", id:'etis'},
    {label: "Budaya Digital", id:'budaya'},
    {label: "Keamanan Digital", id:'keamanan'},
];
class UpdateProfilPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        userid:null,
        password:false,
        data: null,
        message: null,
        isViewPwd:false,
        modal:false,
        fileName:"",
        isEdit:false,
        loading:false,
        rSelected:1,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const p_narsum  = JSON.parse(localStorage.getItem("p_narsum"));
    const p_lainnya = JSON.parse(localStorage.getItem("p_lainnya"));
    const dataUser = JSON.parse(localStorage.getItem("data"));
    const post_ases = JSON.parse(localStorage.getItem("post_ases"));

    console.log(post_ases);
    //console.log(p_lainnya);
    for(var i = 1; i <= p_lainnya.length; i++){
        var stName = "p_desc_"+i;
        var val = p_lainnya[i-1].deskripsi;

        var stName2 = "id_pl_"+i;
        var val2 = p_lainnya[i-1].id;

        this.setState({[stName]: val, [stName2]: val2});
    }

    for(var i = 1; i <= p_narsum.length; i++){
        form_pengalaman[0].form.map(x=>{
            var stName = x.id+"_"+i;
            var val = p_narsum[i-1][x.id];
    
            this.setState({[stName]: val});
        })
    }

    form_regis.map(x=>{
        if(x.type == "radio"){
            if(document.getElementById(dataUser[x.id])){
                document.getElementById(dataUser[x.id]).checked = true;
            }
            
        }

        if(x.type == "table"){
            x.formB.map(y=> {
                this.setState({[y.id]: dataUser[y.id]});
            })
        }
        this.setState({[x.id]: dataUser[x.id]});
    })

    form_post_asesmen.map(x=>{
        if(x.type == "radio"){
            var id_key = "";
            switch(post_ases[x.id]){
                case "Keamanan Digital": 
                    id_key = "keamanan"; break;
                case "Etis Bermedia Digital":
                    id_key = "etis"; break;
                case "Kecakapan Digital":
                    id_key = "kecakapan"; break;
                case "Budaya Digital":
                    id_key = "budaya"; break;
                default:
                    id_key = "";
            }
            //console.log(id_key)
            //console.log("post ases: "+id_key+"_"+x.id);
            if(document.getElementById(id_key+"_"+x.id)){
                document.getElementById(id_key+"_"+x.id).checked = true;
            }
            this.setState({[x.id]: post_ases[x.id]});
        }
        
        if(x.type == "table"){
            x.formB.map(y=>{
                this.setState({[y.id]: post_ases[y.id]});
            })
        }
        
    })

    var srcIframe = process.env.REACT_APP_URL_API+"/upload/"+dataUser.cv;
    this.setState({urlIFrame: srcIframe, cv: dataUser.cv});
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;
    
    if(target.type=="file"){
      //this.setState({[key]:event.target.files[0]});
      this.uploadFile(event.target.files[0]);
    }
    //console.log(value);
    this.setState({
      [key]: value,
      hasChange:true,
    })
  }
  uploadFile(file){
    this.setState({loading: true});
    const data = new FormData();
    data.append('cv',file);

    const requestOptions = {
        ContentType: 'multipart/form-data',
        mode: 'cors',
        method: 'POST',
        body: data
    };
    fetch(process.env.REACT_APP_URL_API+'/app_trainer_upload_cv.php', requestOptions)
        .then(response => response.json())
        .then(respon => {
            var dataAPI = respon;
            //this.setState({loading: false});
            if(dataAPI.error){
            alert(respon.error);
            return;
            }else{
                //this.sendNotif("File berhasil diupload","success");
                var url = URL.createObjectURL(file);
                this.setState({urlIFrame: url, cv:respon.fileName});
        }})
        .catch(e => {
            alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
        })
        .finally(()=>{
            this.setState({loading: false});
        })
}
  submitUpdate(){
    this.setState({loading: true});
    const {default_password,cv} = this.state;
    const dataUser = JSON.parse(localStorage.getItem("data"));
    var body_regis = {};
    body_regis.id_trainer = dataUser.id;
    form_pengalaman.map(x=>{
        if(x.type=="table"){
            var list = [];
            //console.log("x id >"+x.id)
            x.countRow.map(y=>{
                var peng = {};
                x.form.map(z=>{
                    var val = this.state[z.id+"_"+y];
                    if(val==undefined){
                        val="";
                    }
                    peng[z.id] = val;
                })
                //console.log(list);
                list.push(peng);
            })
            body_regis[x.id] = list;
        }
    })
    
    form_regis.map(x=>{
        var elmt = x.id;
        body_regis[elmt] = this.state[elmt]||"";
    })

    for(var x = 0; x < form_post_asesmen.length; x++){
        if(form_post_asesmen[x].type=="table"){
            for(var y = 0; y < form_post_asesmen[x].formB.length; y++){
                var id_state = form_post_asesmen[x].formB[y].id;
                var state_val = this.state[id_state];
                
                body_regis[id_state] = state_val||"";
            }
        }else{
            var id_state = form_post_asesmen[x].id;
            var state_val = this.state[id_state];
            
            body_regis[id_state] = state_val||"";
        }
    }
    //body_regis.id_event = evid;
    //body_regis.password = encrypt(default_password);
    body_regis.cv = cv;
    console.log(body_regis);
    //return;
    const requestOptions = {
        //ContentType: 'multipart/form-data',
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(body_regis)
    };
    fetch(process.env.REACT_APP_URL_API+'/app_trainer_update_bio.php', requestOptions)
        .then(response => response.json())
        .then(respon => {
            var dataAPI = respon;
            //this.setState({loading: false});
            if(dataAPI.error){
                //this.sendNotif(dataAPI.error, 'error');
                alert(respon.error);
                this.props.history.push('/');
                return;
            }else{
                alert(respon.success);
                this.setState({hasChange:false})
                console.log(respon.path);
                localStorage.setItem("data", JSON.stringify(dataAPI.data));
                localStorage.setItem("p_narsum", JSON.stringify(dataAPI.p_narsum));
                localStorage.setItem("p_lainnya", JSON.stringify(dataAPI.p_lainnya));
                localStorage.setItem("post_ases", JSON.stringify(dataAPI.post_ases));
            }})
        .catch(e => {
            alert("Koneksi Terputus, Pastikan Internet Anda Stabil !");
        })
        .finally(()=>{
            this.setState({loading: false});
        })
  }
  
  render() {
    const {hasChange, rSelected} = this.state;
    const dataUser = JSON.parse(localStorage.getItem("data"));
    return (
      <div>
        {modalLoading(this.state.loading)}
        <Card>
            <CardHeader>Update Biodata</CardHeader>
            <CardBody>
              <Row>
                <ButtonGroup style={{marginLeft:15, marginBottom:15}}>
                  <Button
                    color="primary" outline
                    onClick={() => this.setState({ rSelected: 1 })}
                    active={rSelected === 1}
                  >
                    Data Diri
                  </Button>
                  <Button
                    color="primary" outline
                    onClick={() => this.setState({ rSelected: 2 })}
                    active={rSelected === 2}
                  >
                    Pengalaman
                  </Button>
                  <Button
                    color="primary" outline
                    onClick={() => this.setState({ rSelected: 3 })}
                    active={rSelected === 3}
                  >
                    Penguasaan Materi
                  </Button>
                </ButtonGroup>
                <Col sm={12}>
                    <div style={{display:(rSelected===1)?'block':'none'}}>
                    {form_regis.map((x,index)=>(
                        <FormGroup row style={{fontSize:14}} key={index}>
                            <Label sm={3}>
                                {x.label}
                            </Label>
                            <Col sm={7}>
                                {x.type=="radio"?
                                    <Col>
                                        {x.choice.map(y=>(
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        style={{fontSize:14}}
                                                        type={"radio"}
                                                        name={x.id}
                                                        id={y.id}
                                                        value={y.id}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {y.text}
                                                </Label>
                                            </FormGroup>
                                        ))}
                                    </Col>
                                    :
                                    <Input
                                        style={x.style}
                                        type={x.type}
                                        name={x.id}
                                        id={x.id}
                                        value={this.state[x.id]}
                                        placeholder={x.placeholder}
                                        onChange={this.handleInputChange}
                                    />
                                }
                                
                            </Col>
                        </FormGroup>
                    ))}
                    </div>

                    <div style={{display:(rSelected===2)?'block':'none'}}>
                    {form_pengalaman.map((x,index)=>(
                        <Card style={{margin:15}} key={index}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.label}</CardText>
                            </CardBody>
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {x.type=="table"?
                                    <Col>
                                        <Table style={{fontSize:10}} size="sm" responsive {...{ ['' || 'default']: true }}>
                                        <thead>
                                            <tr>
                                            <th style={{width:"13px"}}>No</th>
                                            {x.form.slice(0,x.form.length-1).map(y=>(
                                                <th>{y.label}</th>
                                            ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {x.countRow.map((dt,index)=>(
                                            <tr style={{backgroundColor:(index%2==0)?"#eff4fc":"#fff"}} key={index}>
                                                <td scope="row">{dt}</td>
                                                {x.form.slice(0,x.form.length-1).map((y,index)=>(
                                                    <td scope="row">{
                                                        <Input
                                                            style={{fontSize:14}}
                                                            type={"text"}
                                                            name={y.id+"_"+dt}
                                                            id={y.id+"_"+dt}
                                                            value={this.state[y.id+"_"+dt]}
                                                            onChange={this.handleInputChange}
                                                        />
                                                    }</td>
                                                ))}
                                            </tr>
                                            ))}
                                        </tbody>
                                        </Table>
                                    </Col>
                                    :
                                    <div>
                                    {x.type=="file"?
                                        <iframe id="viewer" 
                                            height="100%"
                                            //key={this.state.keyIframe}
                                            src={this.state.urlIFrame}
                                        />
                                    :null}
                                    <Input
                                        style={x.style}
                                        type={x.type}
                                        name={x.id}
                                        id={x.id}
                                        placeholder={x.placeholder}
                                        onChange={this.handleInputChange}
                                    />
                                    </div>
                                }
                                
                            </CardBody>
                        </Card>
                    ))}
                    </div>
                
                    <div style={{display:(rSelected===3)?'block':'none'}}>
                    {form_post_asesmen.map(x=>(
                        <Card style={{margin:15}}>
                            <CardBody className="d-flex flex-column justify-content-start align-items-start">
                                <CardText style={{fontSize:14}}>{x.label}</CardText>
                            </CardBody>
                            <CardBody className="d-flex justify-content-start align-items-start">
                                {x.type=="table"?
                                    <Col>
                                        <Table style={{fontSize:10}} size="sm" responsive {...{ ['' || 'default']: true }}>
                                        <thead>
                                            <tr>
                                            <th style={{width:"13px"}}>No</th>
                                            {x.formH.map(y=>(
                                                <th>{y.label}</th>
                                            ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {x.formB.map((dt,index)=>(
                                            <tr style={{backgroundColor:(index%2==0)?"#eff4fc":"#fff"}} key={index}>
                                                <td scope="row">{index+1}</td>
                                                <td>{dt.label}</td>
                                                <td scope="row">{
                                                    <Input
                                                        style={{fontSize:14}}
                                                        type={"text"}
                                                        name={dt.id}
                                                        id={dt.id}
                                                        value={this.state[dt.id]}
                                                        onChange={this.handleInputChange}
                                                    />
                                                }</td>
                                            </tr>
                                            ))}
                                        </tbody>
                                        </Table>
                                    </Col>
                                    :
                                    (
                                        x.type=="radio"?
                                        <Col>
                                            {pilihan_tema.map((z,index)=>(
                                                <FormGroup check key={z.id}>
                                                    <Label check>
                                                        <Input
                                                            style={{fontSize:14}}
                                                            type={x.type}
                                                            name={x.id}
                                                            id={z.id+"_"+x.id}
                                                            value={z.label}
                                                            onChange={this.handleInputChange}
                                                        />
                                                        {z.label}
                                                    </Label>
                                                </FormGroup>
                                            ))}
                                        </Col>
                                        :
                                        <Input
                                            style={x.style}
                                            type={x.type}
                                            name={x.id}
                                            id={x.id}
                                            value={this.state[x.id]}
                                            placeholder={x.placeholder}
                                            onChange={this.handleInputChange}
                                        />
                                    )
                                }
                                
                            </CardBody>
                        </Card>
                    ))}
                    </div>
                
                </Col>
                <hr/>
                <Button style={{margin:15, display:(hasChange)?'block':"none"}}
                    onClick={()=>{this.submitUpdate()}}
                >Simpan Perubahan</Button>
              </Row>
            </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(UpdateProfilPage);
