import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import {modalInsertPerngajuanBerkas} from '../../assets/custom/element'
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
  MdInsertDriveFile,
} from 'react-icons/md';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
  Button, Form, Label, Input, FormGroup,
  ListGroup, UncontrolledAlert,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);
var dataBerkas = [];
class Header extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        userid: localStorage.getItem("user_session"),                   
        name: localStorage.getItem("name"),                   
        isEO: localStorage.getItem("name")!='kominfo',                   
        modal:false,
        alert:'none',
        alertMessage:'',
        dataOptionsAkun:[],
        data: [],
        slc_akun: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  componentDidMount(){
    //this.getOptionsAkun();
  }
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleSignout = () => {
    localStorage.clear();
    window.location.href = "/";
  }

  handleInsertPengajuan(){
    this.setState({modal:true})
  }

  getOptionsAkun(){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ userid: localStorage.getItem("user_session") })
    };
    fetch(process.env.REACT_APP_URL_API+'/getOptionsAkun.php', requestOptions)
      .then(response => response.json())
      .then(respon => {
        var dataAPI = respon;
        if(dataAPI.response_code != 200){
          this.setState({ alertMessage: dataAPI.message });
        }else{
          this.setState({ dataOptionsAkun: dataAPI.data });
        }});
  }
  handleInputChange(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;

    dataBerkas[target.name] = value;
    
    if(key == "akun"){
      this.setState({slc_akun: value});
    }
  }
  handleSubmit(){
    var slctdAkun = document.getElementById("akun").selectedIndex;
    var uraian_kegiatan = document.getElementById("uraian_kegiatan").value;

    if(slctdAkun==0){
      this.setState({alertMessage: "Akun belum dipilih", alert: 'block'});
      return;
    }
    if(this.state.slc_akun=="524119" || this.state.slc_akun=="522114"){
      console.log("selected PM PD")
      var radio = document.querySelector('input[name="radio2"]:checked');
      if(radio==null){
        this.setState({alertMessage: "Pilih Salah Satu 'PM/PD'", alert: 'block'});
        return;
      }
    }
    if(uraian_kegiatan==""){
      this.setState({alertMessage: "Uraian Kegiatan belum diisi", alert: 'block'});
      return;
    }
    var userid = this.state.userid;
    var radio = document.querySelector('input[name="radio2"]:checked');
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ userid: userid, akun: dataBerkas["akun"], 
      pm_pd: radio!=null?radio.value:0,
      uraian_kegiatan: dataBerkas["uraian_kegiatan"] })
    };
    fetch(process.env.REACT_APP_URL_API+'/berkasPerwab.php', requestOptions)
      .then(response => response.json())
      .then(respon => {
        var dataAPI = respon;
        if(dataAPI.response_code != 200){
          this.setState({ alertMessage: dataAPI.message });
        }else{
          this.setState({modal:false});
          window.location.reload();
        }});
    console.log(dataBerkas);
  }
  render() {
    const { isNotificationConfirmed, slc_akun } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>
          {/* <SearchInput />
          <Button 
            style={{marginLeft:7}}
            onClick={()=>{this.handleInsertPengajuan()}}>
            <MdInsertDriveFile/> Pengajuan
          </Button> */}
        </Nav>
        <Modal
          style={{
            maxWidth:"1000px"
          }}
          isOpen={this.state.modal}
          //toggle={!this.state.modal}
          backdrop={this.state.backdrop}
        >
          <ModalHeader>
              Pengajuan Berkas Pertanggung Jawaban
          </ModalHeader>
          <ModalBody>
            <UncontrolledAlert style={{display:this.state.alert}} color="danger">
              {this.state.alertMessage}
            </UncontrolledAlert>
            <FormGroup>
              <Label for="exampleSelect">Pilih Akun<Label style={{color:"red"}}>*</Label></Label>
              <Input 
              //style={{maxWidth:100}}
              id={'akun'}
              onChange={this.handleInputChange}
              type="select" name="akun">
                <option value='0'>-- Pilih --</option>
                {this.state.dataOptionsAkun.map((item)=>{
                  return <option key={item.kode_akun} value={item.kode_akun}>{item.kode_akun + " | " + item.keterangan}</option>
                })}
              </Input>
            </FormGroup>
            <div style={{marginBottom:10, display:(slc_akun=="524119" || slc_akun=="524114") ? "block":"none"}} >
            <Label for="checkbox2">Pilih Satu<Label style={{color:"red"}}>*</Label></Label>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" value="PM"/> Paket Meeting
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" value="PD"/> Perjalanan Dinas
                </Label>
              </FormGroup>
            </div>
            <FormGroup>
              <Label for="">Uraian Kegiatan<Label style={{color:"red"}}>*</Label></Label>
              <Input 
                id={'uraian_kegiatan'}
                name={'uraian_kegiatan'}
                onChange={this.handleInputChange}
                type="textarea"
                style={{height:120}}
              >
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
              <Button color="secondary" onClick={()=>{
                this.handleSubmit();
              }}>
                  Simpan
              </Button>{' '}
              <Button color="secondary" outline onClick={()=>{
                  this.setState({
                    modal: !this.state.modal,
                  });
              }}>
                  Cancel
              </Button>
          </ModalFooter>
        </Modal>
        <Nav navbar className={bem.e('nav-right')}>
          
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={localStorage.getItem("name")}
                  subtitle={localStorage.getItem("username")}
                  //text="Last updated 3 mins ago"
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={()=>{
                      window.location.href = '/setting';
                    }}>
                      <MdSettingsApplications /> Ubah Password
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light" onClick={this.handleSignout}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
